<template>
  <div>
    <div class="row mb-2 pe-4">
      <div class="col-lg-8 col-md-7 col-7 d-flex align-items-center">
        <div class="color-common">
          <h3 class="title-page mb-0 ps-3">Menu Set</h3>
        </div>
      </div>
    </div>
    <div class="page-header align-items-start min-vh-100">
      <span class="mask bg-white"></span>
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12 mx-auto">
            <div class="d-flex mt-4 s-menuset align-items-center">
              <span class="me-3">Menu Set</span>
              <Multiselect
                v-model="menuSetIdSelect"
                :options="menusets"
                placeholder="Select menu"
                :canClear="false"
                @change="selectMenuset"
              />
              <a
                class="text-decoration-underline tag-a-custom ms-3"
                @click="this.$router.push('/menu-set/list')"
                >View All Menu Sets</a
              >
            </div>
            <div class="row mt-4" v-if="menuSetIdSelect && isLoadData">
              <div class="d-flex align-items-center">
                <h3 class="title-page mb-0">{{ menuSet.name }}</h3>
                <material-button
                  @click="
                    this.$router.push('/menu-set/detail/' + menuSetIdSelect)
                  "
                  class="mx-3 btn btn-sm btn-background"
                  >View Menu Details</material-button
                >
              </div>
            </div>
            <div class="mt-3" v-if="isShowCategory">
              <div class="row d-flex justify-content-center">
                <ul id="general-tab" class="nav nav-tabs">
                  <li class="nav-item">
                    <a
                      id="nav_item_1"
                      class="nav-link"
                      :class="{ 'active': type == 1 }"
                      @click="changeTab(1)"
                      >MAIN</a
                    >
                  </li>
                  <li class="nav-item">
                    <a id="nav_item_2" 
                      class="nav-link" 
                      :class="{ 'active': type == 2 }"
                      @click="changeTab(2)"
                      >MODIFIER</a
                    >
                  </li>
                  <li class="nav-item">
                    <a id="nav_item_3" class="nav-link" @click="changeTab(3)"
                      >MODIFIER OPTION GROUP</a
                    >
                  </li>
                </ul>
              </div>
              <div v-if="type == 1 || type == 2">
                <div class="mt-4 cate-list">
                  <div class="d-flex align-items-center">
                    <h3 class="title-page mb-0 ps-2">Categories</h3>
                    <material-button
                      @click="openModalCategory()"
                      class="mx-3 btn btn-sm btn-background mw-185px"
                    >
                      <i class="material-icons css-icon">addoutlined</i>
                      Add category
                    </material-button>
                    <draggable 
                      v-model="categories" 
                      group="categories" 
                      @start="drag=true" 
                      @end="onEndDragCategory"
                      item-key="id"
                      class="d-flex"
                    >
                      <template #item="{element}">
                        <div
                          class="cate-card"
                          style="cursor: move;"
                          @click="itemByCategory(element)"
                        >
                          <div class="d-flex align-items-center">
                            <i class="material-icons-round opacity-10"
                              >drag_indicator_icon</i
                            >
                            <img :src="element.img_version" class="me-1" alt="Image" width="35" height="35">
                            <div>{{ element.second_name }}</div>
                          </div>
                        </div>
                      </template>
                    </draggable>
                    <!-- <div class="d-flex flex-wrap" v-if="categories.length">
                      <div
                        class="cate-card"
                        v-for="(category, index) in categories"
                        :key="index"
                      >
                        <div class="d-flex align-items-center">
                          <i class="material-icons-round opacity-10"
                            >drag_indicator_icon</i
                          >
                          <img :src="category.img_version" alt="Image" width="35" height="35">
                          <div>{{ category.second_name }}</div>
                        </div>
                      </div>
                    </div> -->
                  </div>
                  <div v-if="categories.length" style="display: inline-flex;">
                      <!-- <div :class="getClassNameLayoutGrid()" @click="changeLayout('grid')">
                        <i class="material-icons-round opacity-10"
                          >grid_view</i
                        >
                      </div>
                      <div :class="getClassNameLayoutList()" @click="changeLayout('list')">
                        <i class="material-icons-round opacity-10"
                          >list</i
                        >
                      </div> -->
                      <material-button
                        id="dropdownSortCategory" data-bs-toggle="dropdown" data-keyboard="false" data-backdrop="static"
                        class="mx-3 btn btn-sm btn-background mw-185px"
                      >
                        <i class="material-icons css-icon">filter_list_icon</i>
                        Sort categories
                      </material-button>
                      <ul
                          class="dropdown-menu"
                          aria-labelledby="dropdownSortCategory"
                        >
                        <li>
                          <a
                            @click="sortMenuCategory('name')"
                            class="dropdown-item"
                            href="#"
                            >By Name</a
                          >
                        </li>
                        <li>
                          <a
                            @click="sortMenuCategory('modifierDate')"
                            class="dropdown-item"
                            href="#"
                            >By Modifier Date</a
                          >
                        </li>
                        <li>
                          <a
                            @click="sortMenuCategory('createDate')"
                            class="dropdown-item"
                            href="#"
                            >By Created Date</a
                          >
                        </li>
                      </ul>
                    </div>
                </div>
                <!-- <div style="text-align: center;margin-top: 20px;" v-if="(categories && categories.length) && 
                    // !categorySelected || 
                    (categorySelected && categorySelected.groups && categorySelected.groups.length == 0) && typeLayout == 'list'">
                    <material-button
                      @click="openModalGroup()"
                      class="mx-3 btn btn-sm btn-background mw-185px"
                    >
                      <i class="material-icons css-icon">addoutlined</i>
                      Add Group
                    </material-button>
                  </div> -->
                <div
                  v-if="typeLayout == 'list'"
                  style="margin-top: 15px; margin-bottom: 50px"
                >
                  <!-- <div class="row col-12" v-if="categorySelected && categorySelected.groups && categorySelected.groups.length > 0"> -->
                  <div class="d-flex">
                    <div class="m-left-menu">
                      <div
                        v-for="(itemCategory, index) in categories"
                        :key="index"
                      >
                        <div :style="getStyleCategory(index, itemCategory.id)">
                          <div
                            style="width: 80%"
                            @click="itemByCategory(itemCategory)"
                          >
                            {{ itemCategory.second_name }}
                          </div>
                          <div style="width: 10%">
                            <img v-if="itemCategory.is_feature"
                                class="w-100 me-3 mb-0"
                                src="@/assets/img/star_checked.png"
                                alt="logo"
                              />
                              <img v-else
                                class="w-100 me-3 mb-0"
                                src="@/assets/img/star_uncheck.png"
                                alt="logo"
                              />
                          </div>
                          <div
                            v-if="itemCategory.id == categoryIdSelectToHilight"
                            class="dropdown"
                            style="width: 10%; text-align: end"
                          >
                            <i
                              class="dropdownMenuButton"
                              :class="
                                itemCategory.id == categoryIdSelectToHilight
                                  ? 'fas fa-ellipsis-v text-white'
                                  : 'fas fa-ellipsis-v text-dark'
                              "
                              id="dropdownCategory1"
                              data-bs-toggle="dropdown"
                            ></i>
                            <ul
                              class="dropdown-menu"
                              aria-labelledby="dropdownCategory1"
                            >
                              <li>
                                <a
                                  class="dropdown-item"
                                  href="#"
                                  @click="openModalGroup(itemCategory.id)"
                                  >Create Group
                                </a>
                              </li>
                              <li>
                                <a
                                  @click="selectCategory(itemCategory.id)"
                                  class="dropdown-item"
                                  href="#"
                                  >Create Item</a
                                >
                              </li>
                              <li>
                                <a
                                @click="openModalCategory(itemCategory.id, 'edit')"
                                  class="dropdown-item"
                                  href="#"
                                  >Edit Category</a
                                >
                              </li>
                              <li>
                                <a
                                  class="dropdown-item"
                                  href="#"
                                  >Sort Item</a
                                >
                              </li>
                              <li style="padding-left: 20px;">
                                <a
                                  @click="handlSortItem('name')"
                                  class="dropdown-item"
                                  href="#"
                                  >By Name</a
                                >
                              </li>
                              <li style="padding-left: 20px;">
                                <a
                                  @click="handlSortItem('modifierDate')"
                                  class="dropdown-item"
                                  href="#"
                                  >By Modifier Date</a
                                >
                              </li>
                              <li style="padding-left: 20px;">
                                <a
                                  @click="handlSortItem('createDate')"
                                  class="dropdown-item"
                                  href="#"
                                  >By Created Date</a
                                >
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div
                          v-for="(item, index) in itemCategory.groups"
                          :key="index"
                        >
                          <div :style="getStyleGroup(item.id)">
                            <div
                              style="width: 90%; padding-left: 15px"
                              @click="itemByGroup(item)"
                            >
                              <i
                                :class="
                                  item.id == groupIdSelectToHilight
                                    ? 'fas fa-caret-right text-white'
                                    : 'fas fa-caret-right text-dark'
                                "
                                aria-hidden="true"
                              ></i>
                              {{ item.name }}
                            </div>
                            <div
                              v-if="item.id == groupIdSelectToHilight"
                              class="dropdown"
                              style="width: 10%; text-align: end"
                            >
                              <i
                                class="dropdownMenuButton"
                                :class="
                                  item.id == groupIdSelectToHilight
                                    ? 'fas fa-ellipsis-v text-white'
                                    : 'fas fa-ellipsis-v text-dark'
                                "
                                id="dropdownGroup1"
                                data-bs-toggle="dropdown"
                                data-keyboard="false"
                                data-backdrop="static"
                              ></i>
                              <ul
                                class="dropdown-menu"
                                aria-labelledby="dropdownGroup1"
                              >
                                <li>
                                  <a
                                    class="dropdown-item"
                                    href="#"
                                    @click="
                                      openModalAddSubGroup(
                                        item.id,
                                        itemCategory.id
                                      )
                                    "
                                    >Create Sub Group</a
                                  >
                                </li>
                                <li>
                                  <a
                                    @click="
                                      handleAddItem(
                                        itemCategory.id,
                                        item.id,
                                        0,
                                        item,
                                        event
                                      )
                                    "
                                    class="dropdown-item"
                                    href="#"
                                    >Create Item</a
                                  >
                                </li>
                                <li>
                                  <a
                                    class="dropdown-item"
                                    href="#"
                                    @click="
                                      openModalEditGroup(
                                        item.id,
                                        itemCategory.id
                                      )
                                    "
                                    >Edit Group</a
                                  >
                                </li>
                                <li>
                                  <a
                                    class="dropdown-item"
                                    href="#"
                                    @click="deleteGroup(item.id)"
                                    >Delete Group</a
                                  >
                                </li>
                                <li>
                                  <a
                                    class="dropdown-item"
                                    href="#"
                                    >Sort Item</a
                                  >
                                </li>
                                <li style="padding-left: 20px;">
                                  <a
                                    @click="handlSortItem('name')"
                                    class="dropdown-item"
                                    href="#"
                                    >By Name</a
                                  >
                                </li>
                                <li style="padding-left: 20px;">
                                  <a
                                    @click="handlSortItem('modifierDate')"
                                    class="dropdown-item"
                                    href="#"
                                    >By Modifier Date</a
                                  >
                                </li>
                                <li style="padding-left: 20px;">
                                  <a
                                    @click="handlSortItem('createDate')"
                                    class="dropdown-item"
                                    href="#"
                                    >By Created Date</a
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div
                            v-for="(itemSubGroup, index) in item.sub_groups"
                            :key="index"
                            style="
                              border: 1px solid #ccc;
                              border-top: 0px;
                              justify-content: space-between;
                              align-items: center;
                              display: flex;
                            "
                          >
                            <div
                              :style="getStyleSubGroup(itemSubGroup.id)"
                              style="
                                justify-content: space-between;
                                align-items: center;
                                display: flex;
                                width: 100%;
                                padding-left: 35px;
                              "
                            >
                              <div
                                style="width: 90%"
                                @click="itemBySubGroup(itemSubGroup)"
                              >
                                <i
                                  :class="
                                    itemSubGroup.id == subGroupIdSelectToHilight
                                      ? 'fas fa-caret-right text-white'
                                      : 'fas fa-caret-right text-dark'
                                  "
                                  aria-hidden="true"
                                ></i>
                                {{ itemSubGroup.name }}
                              </div>
                              <div
                                v-if="itemSubGroup.id == subGroupIdSelectToHilight"
                                class="dropdown"
                                style="width: 10%; text-align: end"
                              >
                                <i
                                  class="dropdownMenuButton"
                                  :class="
                                    itemSubGroup.id == subGroupIdSelectToHilight
                                      ? 'fas fa-ellipsis-v text-white'
                                      : 'fas fa-ellipsis-v text-dark'
                                  "
                                  aria-hidden="true"
                                  id="dropdownSubGroup1"
                                  data-bs-toggle="dropdown"
                                  data-keyboard="false"
                                  data-backdrop="static"
                                ></i>
                                <ul
                                  class="dropdown-menu"
                                  aria-labelledby="dropdownSubGroup1"
                                >
                                  <li>
                                    <a
                                      class="dropdown-item"
                                      href="#"
                                      @click="
                                        openModalEditSubGroup(
                                          itemSubGroup.id,
                                          itemCategory.id
                                        )
                                      "
                                      >Update Sub Group</a
                                    >
                                  </li>
                                  <li>
                                    <a
                                      class="dropdown-item"
                                      href="#"
                                      @click="deleteGroup(itemSubGroup.id)"
                                      >Delete Sub Group</a
                                    >
                                  </li>
                                  <li>
                                    <a
                                      @click="
                                        handleAddItem(
                                          itemCategory.id,
                                          item.id,
                                          itemSubGroup.id,
                                          itemSubGroup,
                                          event
                                        )
                                      "
                                      class="dropdown-item"
                                      href="#"
                                      >Create Item</a
                                    >
                                  </li>
                                  <li>
                                    <a
                                      class="dropdown-item"
                                      href="#"
                                      >Sort Item</a
                                    >
                                  </li>
                                  <li style="padding-left: 20px;">
                                    <a
                                      @click="handlSortItem('name')"
                                      class="dropdown-item"
                                      href="#"
                                      >By Name</a
                                    >
                                  </li>
                                  <li style="padding-left: 20px;">
                                    <a
                                      @click="handlSortItem('modifierDate')"
                                      class="dropdown-item"
                                      href="#"
                                      >By Modifier Date</a
                                    >
                                  </li>
                                  <li style="padding-left: 20px;">
                                    <a
                                      @click="handlSortItem('createDate')"
                                      class="dropdown-item"
                                      href="#"
                                      >By Created Date</a
                                    >
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="m-right-menu"
                      style="border: 1px solid #ccc"
                      v-if="categories && categories.length && !isLoadList"
                    >
                      <div
                        class="table-responsive p-0 mt-3"
                        style="margin-bottom: 100px; overflow-x: unset !important;"
                      >
                        <table class="table align-items-center mb-0">
                          <thead>
                            <tr>
                              <th></th>
                              <th>Item no</th>
                              <th>Item name</th>
                              <th>Price</th>
                              <th>Group</th>
                              <th>Image</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody v-if="itemByGroupOrSubGroupData.length">
                            <template
                              v-for="(value, key) in itemByGroupOrSubGroupData"
                              :key="key"
                            >
                              <tr :class="{ 'hovered': hoveredRow === key, 'selected': this.checkEditItem[key] && this.checkEditItem[key].isShowEditItem}" @mouseenter="hoveredRow = key" @mouseleave="hoveredRow = null">
                                <td :style="{ backgroundColor: value.button_color }" class="w-1"></td>
                                <td class="text-left text-xss w-15">
                                  {{ value.item_no }}
                                </td>
                                <td class="text-left text-xss w-35" style="white-space: break-spaces;">
                                  <div class="font-weight-bold cl-22">
                                    {{ value.item_name }}
                                  </div>
                                  <div v-if="value.is_combo">
                                    <div
                                      v-for="(
                                        combo, idx
                                      ) in value.combo_elements"
                                      :key="idx"
                                      class="ps-3"
                                    >
                                      {{ "+ " + combo.name }}
                                    </div>
                                  </div>
                                  <div v-else>
                                    <div v-if="value.description">
                                      {{ value.description }}
                                    </div>
                                  </div>
                                </td>
                                <td class="text-left text-xss w-15">
                                  <div v-if="value.variations.length">
                                    <div v-for="(variation, idx) in value.variations" :key="idx">
                                      {{ value.item_no + variation.variation_no + ': ' + variation.name + ' - ' + variationPriceList[categoryIdSelect + '' + groupIdSelect + subGroupIdSelect + value.id + variation.id]}}
                                    </div>
                                  </div>
                                  <div v-else>
                                    {{ value.price_format }}
                                  </div>
                                </td>
                                <td class="text-left text-xss w-15">
                                  {{ value?.group?.group_name }}
                                </td>
                                <td class="text-left text-xss w-15">
                                  <img
                                    :src="value.image"
                                    alt="Image"
                                    width="70"
                                    height="70"
                                  />
                                </td>
                                <td class="text-left text-xss w-4">
                                  <!-- <i
                                      class="material-icons-round cursor-pointer"
                                      id="dropdownMenuButton"
                                      data-bs-toggle="dropdown"
                                      >more_horiz_icon</i
                                    > -->
                                  <i
                                    class="fa fa-ellipsis-v text-secondary cursor-pointer dropdownMenuButton"
                                    aria-hidden="true"
                                    id="dropdownMenuButton"
                                    data-bs-toggle="dropdown"
                                  ></i>
                                  <ul
                                    class="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton"
                                  >
                                    <li>
                                      <a
                                        class="btn btn-link text-dark mb-0 unset-ov"
                                        @click="
                                          editItem(
                                            categoryIdSelect,
                                            value.id,
                                            value.is_combo,
                                            key
                                          )
                                        "
                                      >
                                        <i class="material-icons">edit</i
                                        ><span class="ms-1">Edit</span>
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        class="btn btn-link text-dark mb-0 unset-ov"
                                        @click="disabledItem(value)"
                                      >
                                        <i class="material-icons"
                                          >not_interested</i
                                        >
                                        <span class="ms-1">Disable</span>
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        class="btn btn-link text-dark mb-0 unset-ov"
                                        @click="
                                          addScheduleItem(
                                            value.id,
                                            categoryIdSelect
                                          )
                                        "
                                      >
                                        <i class="material-icons">event_busy</i>
                                        <span class="ms-1">Add Schedule</span>
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        class="btn btn-link text-dark mb-0 unset-ov"
                                        @click="
                                          moveUpDown(value.pivot.id, 'up')
                                        "
                                      >
                                        <i class="material-icons"
                                          >arrow_upward</i
                                        >
                                        <span class="ms-1">Move Up</span>
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        class="btn btn-link text-dark mb-0 unset-ov"
                                        @click="
                                          moveUpDown(value.pivot.id, 'down')
                                        "
                                      >
                                        <i class="material-icons"
                                          >arrow_downward</i
                                        >
                                        <span class="ms-1">Move Down</span>
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        class="btn btn-link text-dark mb-0 unset-ov"
                                        @click="
                                          deattachItem(value.pivot.id)
                                        "
                                      >
                                        <i class="material-icons"
                                          >category</i
                                        >
                                        <span class="ms-1">Deattach</span>
                                      </a>
                                    </li>
                                  </ul>
                                </td>
                              </tr>
                              <tr v-if="checkEditItem[key] && checkEditItem[key].isShowEditItem" style="background: #ebecee ;">
                                <td colspan="7" v-if="value.is_combo">
                                  <EditCombo
                                    :itemId="value.id"
                                    :categoryId="categoryIdSelect"
                                    :setId="menuSetIdSelect"
                                    @add-schedule-item="addScheduleItem"
                                    @hide-edit-item="hideEditItem(key)"
                                    @reload-list-category="reloadListCategory"
                                  ></EditCombo>
                                </td>
                                <td colspan="7" v-else>
                                  <EditItem
                                    :itemId="value.id"
                                    :categoryId="categoryIdSelect"
                                    :setId="menuSetIdSelect"
                                    @add-schedule-item="addScheduleItem"
                                    @hide-edit-item="hideEditItem(key)"
                                    @reload-list-category="reloadListCategory"
                                  ></EditItem>
                                </td>
                              </tr>
                            </template>
                          </tbody>
                          <tbody v-else>
                            <tr>
                              <td colspan="8" class="text-center">
                                No item available
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="mt-3 category-box" v-if="categories.length == 0">
                    <div
                      class="d-flex align-items-center cursor-pointer"
                      @click="openModalCategory()"
                    >
                      <i class="material-icons-round opacity-10">addoutlined</i>
                      <div class="mt-1">Add category</div>
                    </div>
                  </div>
                  <div v-else>
                    <div
                      class="mt-3 category-box"
                      v-for="(category, index) in categories"
                      :key="index"
                    >
                      <div class="category-detail w-100">
                        <div
                          class="d-flex align-items-center justify-content-between"
                        >
                          <div class="d-flex align-items-center">
                            <h3 class="title-page mb-0 ps-2 pe-3">
                              {{ category.second_name }}
                            </h3>
                            <i
                              style="cursor: pointer"
                              class="material-icons-round opacity-10 fs-5 pe-5 cl-22"
                              @click="openModalCategory(category.id, 'edit')"
                              >edit</i
                            >
                            <div
                              @click="selectCategory(category.id)"
                              class="d-flex align-items-center cl-22 cursor-pointer"
                            >
                              <i class="material-icons-round opacity-10"
                                >addoutlined</i
                              >
                              <div class="mt-1">Add menu items</div>
                            </div>
                          </div>
                          <div class="d-flex align-items-center">
                            <div>
                              <material-button
                                @click="sortMenuItems()"
                                class="mx-3 btn btn-sm btn-background"
                              >
                                <i class="material-icons css-icon"
                                  >filter_list_icon</i
                                >
                                Sort menu items
                              </material-button>
                            </div>
                          </div>
                        </div>
                        <div class="row item-list mt-3">
                          <template v-if="category?.items?.length">
                            <div
                              class="col-md-6 col-12"
                              v-for="(item, key) in category.items"
                              :key="key"
                            >
                              <div class="card-item">
                                <div class="d-flex">
                                  <img :src="item.image" alt />
                                  <div class="card-content ms-3">
                                    <div class="w-100 d-flex">
                                      <h5
                                        class="w-75 one-line hide-text text-break"
                                      >
                                        {{ item.item_name }}
                                      </h5>
                                      <div
                                        class="w-25 d-flex justify-content-between"
                                      >
                                        <material-checkbox
                                          :checked="true"
                                          class="align-items-center checkbox-custom"
                                        ></material-checkbox>
                                        <i
                                          class="material-icons-round opacity-10 fs-5 cl-22 cursor-pointer"
                                          id="dropdownMenuButton"
                                          data-bs-toggle="dropdown"
                                          >more_horiz_icon</i
                                        >
                                        <ul
                                          class="dropdown-menu"
                                          aria-labelledby="dropdownMenuButton"
                                        >
                                          <li>
                                            <a
                                              class="btn btn-link text-dark mb-0 unset-ov"
                                              @click="
                                                editItem(category.id, item.id)
                                              "
                                            >
                                              <i class="material-icons">edit</i
                                              ><span class="ms-1">Edit</span>
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              class="btn btn-link text-dark mb-0 unset-ov"
                                              @click="disabledItem(item)"
                                            >
                                              <i class="material-icons"
                                                >not_interested</i
                                              >
                                              <span class="ms-1">Disable</span>
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              class="btn btn-link text-dark mb-0 unset-ov"
                                              @click="
                                                addScheduleItem(
                                                  item.id,
                                                  category.id
                                                )
                                              "
                                            >
                                              <i class="material-icons"
                                                >event_busy</i
                                              >
                                              <span class="ms-1"
                                                >Add Schedule</span
                                              >
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              class="btn btn-link text-dark mb-0 unset-ov"
                                              @click="
                                                moveUpDown(item.pivot.id, 'up')
                                              "
                                            >
                                              <i class="material-icons"
                                                >arrow_upward</i
                                              >
                                              <span class="ms-1">Move Up</span>
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              class="btn btn-link text-dark mb-0 unset-ov"
                                              @click="
                                                moveUpDown(
                                                  item.pivot.id,
                                                  'down'
                                                )
                                              "
                                            >
                                              <i class="material-icons"
                                                >arrow_downward</i
                                              >
                                              <span class="ms-1"
                                                >Move Down</span
                                              >
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <h5>{{ item.price_format }}</h5>
                                  </div>
                                </div>
                                <div class="text-center cl-22">
                                  No allergens
                                </div>
                              </div>
                            </div>
                          </template>
                          <div class="col-md-6 col-12" v-else>
                            <div class="card-item add-items">
                              <div
                                @click="selectCategory(category.id)"
                                class="d-flex align-items-center cl-22 cursor-pointer"
                              >
                                <i class="material-icons-round opacity-10"
                                  >addoutlined</i
                                >
                                <div class="mt-1">Add menu items</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="type == 3">
                <!-- modifier option group here -->
                <ModifierOptionGroup
                  :dataPreiewItemSelected="this.dataPreiewItemSelected"
                  :reloadModifierOptionGroup="this.reloadModifierOptionGroup"
                  :menu_set_id="this.menuSet.id"
                  @childEvent="handleChildEventModifierOptionGroup"
                  @reload-list-attached-to-item="reloadListAttachedToItem"
                  @delete-item="deleteItemOnModifierOptionGroup"
                  :date_time_now="new Date()"
                  @delete-modifier-option-group="deleteModifierOptionGroup"
                ></ModifierOptionGroup>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="position-fixed top-1 end-1 z-index-2 message-success">
    <material-snackbar
      v-if="snackbar === 'danger' || snackbar === 'success'"
      title
      date
      :description="message"
      :icon="{ component: 'campaign', color: 'white' }"
      :color="snackbar"
      :close-handler="closeSnackbar"
    />
  </div>
  <material-loading :active="loaderActive" />
  <AddCategoryModal
    :keyReload="keyReloadAddCategory"
    @reload-list-category="reloadListCategory"
    :categoryType="type"
    :isEditCategory="isEditCategory"
    :categoryId="categoryId"
    :setId="menuSetIdSelect"
  ></AddCategoryModal>
  <CreateModalMenuItem
    :isModifiderOptionGroup="isModifiderOptionGroup"
    :modifier_option_group_id="modifier_option_group_id"
    :attachedToItemModifierOptionGroup="attachedToItemModifierOptionGroup"
    :setId="menuSetIdSelect"
    :categoryId="categoryIdSelect"
    :groupId="groupIdSelect"
    :subGroupId="subGroupIdSelect"
    :keyReload="keyReload"
    @create-new-item="createNewItem"
    @create-new-combo="createNewCombo"
    @reload-list-category="reloadListCategory"
    @reload-list-attached-to-item="reloadListAttachedToItem"
    @add-preiew-item-selected="addPreiewItemSelected"
    :date_time_now="new Date()"
    :itemByGroupOrSubGroupData="itemByGroupOrSubGroupData"
  ></CreateModalMenuItem>
  <CreateGroupAndSubGroup
    :keyReload="keyReloadModalCreateGroupAndSubGroup"
    :setId="menuSetIdSelect"
    :categoryId="categoryIdSelect"
    :groupId="groupIdSelect"
    :subGroupId="subGroupIdSelect"
    :type="type"
    :isUpdate="isUpdateGroupAndSubGroup"
    :isUpdateSubGroup="isUpdateSubGroup"
    @reload-list-category="reloadListCategory"
    :date_time_now="new Date()"
  ></CreateGroupAndSubGroup>
  <ModalMenuSetSchedule
    :keyReload="keyReloadScheduleModal"
    @reload-schedule="reloadSchedule"
    :setIdSelect="menuSetIdSelect"
    :branchIdSelectEdit="branchIdSelectEdit"
    :categoryIdSelect="categoryIdSelect"
    :itemIdSelect="itemIdSelect"
  ></ModalMenuSetSchedule>
  <ModalConfirmDelete
    message="Are you sure you want to delete?"
    :process-yes="removeItemOnModifierOptionGroup"
  />
  <ModalConfirmGroup
    message="Are you sure you want to delete?"
    :process-yes="removeGroup"
  />
  <ModalConfirmDeleteModifierOptionGroup
    message="Are you sure you want to delete?"
    :process-yes="handleDeleteModifierOptionGroup"
  />
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import "bootstrap/dist/js/bootstrap.bundle";
import MaterialLoading from "@/components/MaterialLoading.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import $ from "jquery";
import AddCategoryModal from "@/components/AddCategory.vue";
import CreateGroupAndSubGroup from "@/components/CreateGroupAndSubGroup.vue";
import CreateModalMenuItem from "@/components/CreateModalMenuItem.vue";
import MenusetService from "../../services/product/menuset.service";
import Multiselect from "@vueform/multiselect";
import ItemService from "../../services/product/item.service";
import ModalConfirmDelete from "@/components/ModalConfirmDelete.vue";
import ModalConfirmGroup from "@/components/ModalConfirmGroup.vue";
import ModifierOptionGroup from "./ModifierOptionGroup.vue";
import ModifierOptionGroupService from "../../services/product/modifierOptionGroup";
import ModifierGroupService from "../../services/product/modifierGroup.service";
import ModalConfirmDeleteModifierOptionGroup from "@/components/ModalConfirmDeleteModifierOptionGroup.vue";
import EditItem from "../menu/EditItem.vue";
import EditCombo from "../menu/EditCombo.vue";
import ModalMenuSetSchedule from "@/components/ModalMenuSetSchedule.vue";
import draggable from 'vuedraggable'

export default {
  name: "create-menu",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    MaterialButton,
    AddCategoryModal,
    CreateModalMenuItem,
    MaterialCheckbox,
    Multiselect,
    ModifierOptionGroup,
    ModalConfirmDelete,
    CreateGroupAndSubGroup,
    ModalConfirmGroup,
    ModalConfirmDeleteModifierOptionGroup,
    EditItem,
    EditCombo,
    ModalMenuSetSchedule,
    draggable
  },
  data() {
    return {
      isLoadData: false,
      snackbar: null,
      message: "",
      loaderActive: false,
      menusets: [],
      menuSet: {
        id: 0,
        name: "",
        description: "",
        image: null,
        // branchIdSelect: [],
        priceList: [],
      },
      isShowCategory: false,
      categories: [],
      variationPriceList: [],
      type: this.$route.query.type ?? 1,
      categoryId: 0,
      categoryIdSelect: 0,
      groupIdSelect: 0,
      subGroupIdSelect: 0,
      itemIdSelect: 0,
      itemSelectInfo: {},
      menuSetIdSelect: this.$route.query.set_id ?? 0,
      keyReload: "key-reload",
      keyReloadAddCategory: "key-reload-add-category",
      keyReloadScheduleModal: "key-reload-schedule-modal",
      keyReloadModalAddItem: "key-reload-modal-add-item",
      keyReloadModalCreateGroupAndSubGroup:
        "key-reload-modal-create-group-and-sub-group",
      sortCategory: "",
      sortCateogryType: "",
      sortItem: "",
      isEditCategory: false,
      branchIdSelectDelete: 0,
      branchIdSelectEdit: 0,
      // branches: [],
      scheduleList: [],
      isUpdateItem: false,
      isUpdateGroupAndSubGroup: false,
      isUpdateSubGroup: false,
      itemScheduleList: [],
      priceListDisplay: [],
      isModifiderOptionGroup: false,
      modifier_option_group_id: 0,
      reloadModifierOptionGroup: "",
      itemIdInModifierOptionGroupId: 0,
      dataPreiewItemSelected: [],
      typeLayout: "list",
      categorySelected: null,
      group_id_to_delete: 0,
      itemByGroupOrSubGroupData: [],
      attachedToItemModifierOptionGroup: [],
      groupIdSelectToHilight: 0,
      subGroupIdSelectToHilight: 0,
      categoryIdSelectToHilight: 0,
      idModifierOptionGroupId: 0,
      checkEditItem: [],
      hoveredRow: null,
      sortDirections: {
        name: 'desc',
        modifierDate: 'desc',
        createDate: 'desc',
      },
      drag: false,
    };
  },
  async created() {
    await this.changeTab(this.type);
    // this.getListCategory();
    await this.getMenusets();
    if (this.menuSetIdSelect) {
      await this.selectMenuset(this.menuSetIdSelect);

      this.categoryIdSelect = this.$route.query.category_id ?? 0;
      if (this.categoryIdSelect) {
        let category = this.categories.find(item => item.id == this.categoryIdSelect);
        this.groupIdSelect = this.$route.query.group_id ?? 0;

        if (category) {
          if (this.groupIdSelect) {
            let group = category.groups.find(item => item.id == this.groupIdSelect);
            this.subGroupIdSelect = this.$route.query.sub_group_id ?? 0;

            if (group) {
              if (this.subGroupIdSelect) {
                let subgroup = group.sub_groups.find(item => item.id == this.subGroupIdSelect);

                if (subgroup) {
                  this.itemBySubGroup(subgroup);
                }
              } else {
                this.itemByGroup(group);
              }
            }
          } else {
            this.itemByCategory(category);
          }
        }
      }
    }
  },
  mounted() {
    this.getPriceList();
  },
  methods: {
    async changeTab(type) {
      this.categorySelected = [];
      this.itemByGroupOrSubGroupData = [];
      this.checkEditItem = [];
      this.attachedToItemModifierOptionGroup = [];
      this.isModifiderOptionGroup = false;
      this.type = type;
      $("#general-tab .nav-link").removeClass("active");
      $("#nav_item_" + type).addClass("active");
      await this.getListCategory();
    },

    async selectMenuset(menuSetId) {
      this.clearData();
      this.menuSetIdSelect = menuSetId;
      await this.getMenuDetail();
      await this.getListCategory();
    },

    async getMenuDetail() {
      this.isLoadData = false;
      if (this.menuSetIdSelect) {
        this.showLoader();
        await MenusetService.menusetDetail(this.menuSetIdSelect).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.menuSet = response.data.data;
              this.isShowCategory = true;
              this.isLoadData = true;
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      }
    },

    async getMenusets() {
      this.showLoader();
      var dataForm = {
        type: "select",
      };
      await MenusetService.getList(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.menusets = response.data.data;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    disabledItem(item) {
      this.showLoader();
      var dataForm = {
        id: item.id,
        value: item.is_disabled,
      };
      ItemService.handleEnableOrDisable(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.getListCategory();
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.setTimeOutNotification();
        }
      );
      this.hideLoader();
    },

    getPriceList(setId = 0) {
      this.showLoader();
      var dataForm = {
        set_id: setId ? setId : this.$route.params.id,
      };

      MenusetService.getPriceList(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.menuSet.priceList = response.data.data.price_list;
            this.priceListDisplay = response.data.data.price_list_display;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    async getItemDetail(itemId) {
      this.showLoader();
      await ItemService.itemDetail(itemId).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.itemSelectInfo = response.data.data;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    clearData() {
      this.menuSetIdSelect = 0;
      this.categoryIdSelect = 0;
      this.groupIdSelect = 0;
      this.subGroupIdSelect = 0;
      this.itemIdSelect = 0;
      this.type = this.$route.query.type ?? 1;
      this.sortCategory = "";
      this.sortCateogryType = "";
      this.sortItem = "";
      this.categories = [];
      this.itemByGroupOrSubGroupData = [];
      this.attachedToItemModifierOptionGroup = [];
      this.groupIdSelectToHilight = 0;
      this.subGroupIdSelectToHilight = 0;
      this.categoryIdSelectToHilight = 0;
      this.idModifierOptionGroupId = 0;
      this.isModifiderOptionGroup = false;
      this.categorySelected = [];
      this.isLoadData = false;
      this.isShowCategory = false;
      this.checkEditItem = [];
    },

    async getListCategory() {
      this.showLoader();
      var dataForm = {
        set_id: this.menuSetIdSelect,
        type: this.type,
        sort_category: this.sortCategory,
        sort_category_type: this.sortCateogryType,
        sort_item: this.sortItem,
      };

      await MenusetService.getListCategory(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.categories = response.data.data.categories;
            this.variationPriceList = response.data.data.variation_price_list;

            let targetItems = [];
            this.categories.forEach((category) => {
              if (category.id === this.categoryIdSelectToHilight) {
                targetItems = category.items;
              }
              category.groups.forEach((group) => {
                if (group.id === this.groupIdSelectToHilight) {
                  targetItems = group.items;
                } else {
                  group.sub_groups.forEach((subGroup) => {
                    if (subGroup.id === this.subGroupIdSelectToHilight) {
                      targetItems = subGroup.sub_groupitems;
                    }
                  });
                }
              });
            });
            if (targetItems) {
              this.itemByGroupOrSubGroupData = targetItems;
              this.checkShowEditItem();
            }

            // console.log(targetItems);
            if (response.data.data && response.data.data.length > 0) {
              this.selectedCategory(response.data.data[0].id);
            }
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    checkShowEditItem() {
      this.checkEditItem = [];
      this.itemByGroupOrSubGroupData.forEach(() => {
        this.checkEditItem.push({
          isShowEditItem: false,
        });
      });
    },

    hideEditItem(index) {
      this.checkEditItem[index] = [];
    },

    moveUpDown(categoryItemId, type) {
      var dataForm = {
        category_item_id: categoryItemId,
        type: type,
      };

      ItemService.moveUpDown(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.reloadListCategory("Move " + type + " success");
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    deattachItem(id) {
      MenusetService.deattachItem(id).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.reloadListCategory("Deattach item success");
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    reloadListCategory(mess) {
      this.categoryId = 0;
      this.snackbar = "success";
      this.message = mess;
      this.setTimeOutNotification();
      this.getListCategory();
    },

    selectCategory(id) {
      this.categoryIdSelect = id;
      this.keyReload = Math.random();
      this.isUpdateItem = false;
      $("#show-modal-select-item").click();
    },

    async editItem(categoryId, itemId, isCombo = 0, index) {
      console.log(categoryId, itemId, isCombo);
      this.checkEditItem.forEach((item) => {
        item.isShowEditItem = false;
      });
      this.checkEditItem[index].isShowEditItem = true;
      // var url = '/edit-item/';
      // if (isCombo) {
      //   url = '/edit-combo/';
      // }

      // this.$router.push(url + itemId + '?set_id=' + this.menuSetIdSelect + '&category_id=' + categoryId)
      // this.categoryIdSelect = categoryId;
      // this.itemIdSelect = itemId;
      // await this.getItemDetail(itemId);
      // this.getScheduleInfo();
      // this.isUpdateItem = true;
      // $("#show-modal-add-item").click();
    },

    openModalCategory(id = 0, type = "add") {
      this.keyReloadAddCategory = Math.random();
      this.categoryId = id;
      if (type == "add") {
        this.isEditCategory = false;
      } else {
        this.isEditCategory = true;
      }
      $("#show-modal-add-category").click();
    },

    createNewItem() {
      // this.keyReloadModalAddItem = Math.random();
      // $("#show-modal-add-item").click();
      var url =
        "/create-item?type=" +
        this.type +
        "&set_id=" +
        this.menuSetIdSelect +
        "&category_id=" +
        this.categoryIdSelect;

      if (this.groupIdSelect) {
        url += "&group_id=" + this.groupIdSelect;
      }

      if (this.subGroupIdSelect) {
        url += "&sub_group_id=" + this.subGroupIdSelect;
      }

      this.$router.push(url);
    },

    createNewCombo() {
      // this.keyReloadModalAddItem = Math.random();
      // $("#show-modal-add-item").click();
      var url =
        "/create-combo?type=" +
        this.type +
        "&set_id=" +
        this.menuSetIdSelect +
        "&category_id=" +
        this.categoryIdSelect;

      if (this.groupIdSelect) {
        url += "&group_id=" + this.groupIdSelect;
      }

      if (this.subGroupIdSelect) {
        url += "&sub_group_id=" + this.subGroupIdSelect;
      }

      this.$router.push(url);
    },

    addScheduleItem(itemId, categoryId) {
      this.branchIdSelectEdit = 0;
      this.categoryIdSelect = categoryId;
      this.itemIdSelect = itemId;
      this.keyReloadScheduleModal = Math.random();
      // if (itemId) {
      //   $(".btn-close-modal").click();
      // }
      $("#show-modal-menuset-schedule").click();
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },

    confirmDelete(branchId) {
      this.branchIdSelectDelete = branchId;
      $(".btn-confirm-modal").click();
    },

    sortMenuCategory(type) {
      this.sortCateogryType = type;
      if (this.sortCategory == "" || this.sortCategory == "asc") {
        this.sortCategory = "desc";
      } else {
        this.sortCategory = "asc";
      }
      this.getListCategory();
    },

    sortMenuItems() {
      if (this.sortItem == "" || this.sortItem == "asc") {
        this.sortItem = "desc";
      } else {
        this.sortItem = "asc";
      }
      this.getListCategory();
    },

    handleChildEventModifierOptionGroup(data) {
      // console.log(data);
      this.keyReload = Math.random();
      this.isModifiderOptionGroup = true;
      this.modifier_option_group_id = data.modifier_option_group_id;
      this.attachedToItemModifierOptionGroup = data.attachedToItem;
      $(".btn-select-item-modal").click();
    },

    reloadListAttachedToItem(mess) {
      this.reloadModifierOptionGroup = mess;
    },

    deleteItemOnModifierOptionGroup(item_id) {
      this.itemIdInModifierOptionGroupId = item_id;
      $(".btn-confirm-modal-delete").click();
    },

    removeItemOnModifierOptionGroup() {
      $(".btn-close-modal").click();
      this.showLoader();
      ModifierOptionGroupService.deleteItem(
        this.itemIdInModifierOptionGroupId
      ).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.reloadModifierOptionGroup = "Delete Success";
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    addPreiewItemSelected(data) {
      console.log(data);
      this.dataPreiewItemSelected = data;
    },

    getClassNameLayoutList() {
      if (this.typeLayout == "list") {
        return "activeLayout d-flex align-items-center";
      } else {
        return "inActiveLayout d-flex align-items-center";
      }
    },

    getClassNameLayoutGrid() {
      if (this.typeLayout != "list") {
        return "activeLayout d-flex align-items-center";
      } else {
        return "inActiveLayout d-flex align-items-center";
      }
    },

    changeLayout(type) {
      this.typeLayout = type;
    },

    selectedCategory(category_id) {
      const foundCategory = this.categories.find(
        (obj) => obj.id === category_id
      );
      this.categorySelected = foundCategory;
    },

    openModalGroup(category_id) {
      // this.categoryIdSelect = this.categorySelected.id;
      this.categoryIdSelect = category_id;
      this.isUpdateGroupAndSubGroup = false;
      this.isUpdateSubGroup = false;
      this.groupIdSelect = 0;
      this.subGroupIdSelect = 0;

      $("#show-modal-create-group-and-sub-group").click();
    },
    openModalEditGroup(group_id, category_id) {
      // this.categoryIdSelect = this.categorySelected.id;
      this.categoryIdSelect = category_id;
      this.isUpdateGroupAndSubGroup = true;
      this.isUpdateSubGroup = false;
      this.groupIdSelect = group_id;
      this.subGroupIdSelect = 0;

      $("#show-modal-create-group-and-sub-group").click();
    },
    openModalAddSubGroup(group_id, category_id) {
      // this.categoryIdSelect = this.categorySelected.id;
      this.categoryIdSelect = category_id;
      this.isUpdateGroupAndSubGroup = false;
      this.isUpdateSubGroup = false;
      this.groupIdSelect = group_id;
      this.subGroupIdSelect = 0;

      $("#show-modal-create-group-and-sub-group").click();
    },
    openModalEditSubGroup(group_id, category_id) {
      // this.categoryIdSelect = this.categorySelected.id;
      this.categoryIdSelect = category_id;
      this.isUpdateGroupAndSubGroup = false;
      this.isUpdateSubGroup = true;
      this.groupIdSelect = 0;
      this.subGroupIdSelect = group_id;

      $("#show-modal-create-group-and-sub-group").click();
    },
    deleteGroup(group_id) {
      this.group_id_to_delete = group_id;
      $(".btn-confirm-group-modal").click();
    },
    removeGroup() {
      $(".btn-close-modal").click();
      this.showLoader();
      ModifierGroupService.deleteGroup(this.group_id_to_delete).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.snackbar = "success";
            this.message = "Delete Successfully!";
            this.getListCategory();
            this.setTimeOutNotification();
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },
    handleAddItem(category_id, group_id, sub_group_id, item, event) {
      if (event) {
        event.preventdefault();
      }
      // console.log(item);
      if (item && item.sub_groupitems) {
        // console.log("sub");
        this.itemBySubGroup(item);
      } else {
        // console.log("group");
        this.itemByGroup(item);
      }
      // this.itemByGroupOrSubGroupData = item.sub_groupitems ? item.sub_groupitems : item.items;
      this.groupIdSelect = group_id;
      this.subGroupIdSelect = sub_group_id;
      this.selectCategory(category_id);
    },
    itemByCategory(item) {
      this.closeAllDropdowns();
      this.sortDirections = {
        name: 'desc',
        modifierDate: 'desc',
        createDate: 'desc',
      }
      this.categoryIdSelect = item.id;
      this.groupIdSelect = 0;
      this.subGroupIdSelect = 0;
      // console.log(item);
      this.categoryIdSelectToHilight = item.id;
      this.groupIdSelectToHilight = 0;
      this.subGroupIdSelectToHilight = 0;
      this.itemByGroupOrSubGroupData = item.items;
      this.checkShowEditItem();
    },
    itemByGroup(item) {
      this.closeAllDropdowns();
      this.sortDirections = {
        name: 'desc',
        modifierDate: 'desc',
        createDate: 'desc',
      }
      this.categoryIdSelect = item.category_id;
      this.groupIdSelect = item.id;
      this.subGroupIdSelect = 0;
      // console.log(item);
      this.categoryIdSelectToHilight = 0;
      this.groupIdSelectToHilight = item.id;
      this.subGroupIdSelectToHilight = 0;
      this.itemByGroupOrSubGroupData = item.items;
      this.checkShowEditItem();
    },
    itemBySubGroup(item) {
      this.closeAllDropdowns();
      this.sortDirections = {
        name: 'desc',
        modifierDate: 'desc',
        createDate: 'desc',
      }
      this.categoryIdSelect = item.category_id;
      this.groupIdSelect = item.parent_id;
      this.subGroupIdSelect = item.id;
      // console.log(item);
      this.categoryIdSelectToHilight = 0;
      this.groupIdSelectToHilight = 0;
      this.subGroupIdSelectToHilight = item.id;
      this.itemByGroupOrSubGroupData = item.sub_groupitems;
      this.checkShowEditItem();
    },
    getStyleCategory(index, categoryId) {
      const isHighlighted = this.categoryIdSelectToHilight === categoryId;
      return {
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex",
        width: "100%",
        border: "#ccc",
        background: isHighlighted ? "#82001e" : "white",
        color: isHighlighted ? "white" : "black",
        paddingLeft: "10px",
        paddingRight: "10px",
        paddingTop: "5px",
        paddingBottom: "5px",
        borderTop: index == 0 ? "1px solid #ccc" : "unset",
        borderBottom: "1px solid #ccc",
        borderLeft: "1px solid #ccc",
        borderRight: "1px solid #ccc",
        cursor: "pointer",
      };
    },

    getStyleGroup(itemId) {
      const isHighlighted = this.groupIdSelectToHilight === itemId;
      return {
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex",
        width: "100%",
        border: "#ccc",
        background: isHighlighted ? "#82001e" : "white",
        color: isHighlighted ? "white" : "black",
        paddingLeft: "5px",
        paddingRight: "10px",
        paddingTop: "5px",
        paddingBottom: "5px",
        borderBottom: "1px solid #ccc",
        borderLeft: "1px solid #ccc",
        borderRight: "1px solid #ccc",
        cursor: "pointer",
      };
    },

    getStyleSubGroup(itemId) {
      const isHighlighted = this.subGroupIdSelectToHilight === itemId;
      return {
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex",
        width: "100%",
        paddingRight: "10px",
        paddingLeft: "15px",
        paddingTop: "5px",
        paddingBottom: "5px",
        background: isHighlighted ? "#82001e" : "white",
        color: isHighlighted ? "white" : "black",
        cursor: "pointer",
      };
    },

    closeAllDropdowns() {
      const dropdownMenus = document.querySelectorAll("ul.dropdown-menu.show");
      dropdownMenus.forEach((menu) => {
        menu.classList.remove("show");
        menu.classList.add("hide");
      });
    },

    deleteModifierOptionGroup(item_id) {
      this.idModifierOptionGroupId = item_id;
      $(".btn-confirm-modal-delete-modifier-option-group").click();
    },

    handleDeleteModifierOptionGroup() {
      $(".btn-close-modal").click();
      this.showLoader();
      ModifierOptionGroupService.delete(this.idModifierOptionGroupId).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.reloadModifierOptionGroup = "Delete Success";
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },
    handlSortItem(type) {
      try {
        let data = this.itemByGroupOrSubGroupData.slice();

        const reverseSortDirection = (type) => {
          this.sortDirections[type] = this.sortDirections[type] === 'asc' ? 'desc' : 'asc';
        };

        switch (type) {
          case 'name':
            data.sort((a, b) =>
              this.sortDirection === 'asc'
                ? a.item_name.localeCompare(b.item_name)
                : b.item_name.localeCompare(a.item_name)
            );
            break;
          case 'modifierDate':
            data.sort((a, b) =>
              this.sortDirection === 'asc'
                ? new Date(a.updated_at) - new Date(b.updated_at)
                : new Date(b.updated_at) - new Date(a.updated_at)
            );
            break;
          case 'createDate':
            data.sort((a, b) =>
              this.sortDirection === 'asc'
                ? new Date(a.created_at) - new Date(b.created_at)
                : new Date(b.created_at) - new Date(a.created_at)
            );
            break;
          default:
            console.error('Invalid sorting type:', type);
            return;
        }

        this.itemByGroupOrSubGroupData = data;
        console.log('Data sorted successfully by', type);

        reverseSortDirection();
      } catch (error) {
        console.error('Error sorting data:', error.message);
      }
    },
    onEndDragCategory() {
      if(this.categories && this.categories.length > 1) {
        this.showLoader();
        var dataForm = {
          orderCategories: this.categories
        };
        MenusetService.updateCategoriesOrder(dataForm).then(
          (response) => {
            if (response.data.result.isSuccess) {
              console.log(response.data);
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      }
    }
  },
};
</script>
<style scoped>
.page-header .container {
  color: #222 !important;
}
.dowload-img {
  text-align: center;
  cursor: pointer;
  background-color: #f0f2f5;
  width: 80%;
  height: 350px;
}
.add-category {
  text-align: center;
  cursor: pointer;
  background-color: #f0f2f5;
  width: 100%;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.w-35 {
  width: 35%;
}
.w-65 {
  width: 65%;
}
.css-icon-upload {
  font-size: 120px !important;
  margin-top: 4rem;
}

.css-icon {
  position: absolute;
  left: 17px !important;
  font-size: 18px !important;
  top: 7px !important;
}

.cate-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d5d6d8;
  background: #ebecee !important;
  padding: 15px 0;
  border-radius: 5px;
}

.cate-list > div:first-child {
  width: calc(100% - 217px);
}

.cate-list > div:first-child > div {
  width: calc(100% - 317px);
  overflow-x: auto;
}

.category-box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;
  border: 1px solid #d5d6d8;
  color: #82001e;
  border-radius: 5px;
}

.category-box:last-child {
  margin-bottom: 50px;
}

.cate-card {
  display: flex;
  align-items: center;
  border: 1px solid #ffffff;
  background: #ffffff;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 5px;
}

.cate-card > div div:last-child {
  white-space: nowrap;
}

.card-item.add-items {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 75px 0 !important;
}
.card-item {
  border: 1px solid #d5d6d8;
  color: #82001e;
  border-radius: 5px;
  padding: 7px;
  margin-top: 15px;
}

.card-item img {
  width: 175px !important;
  height: 175px !important;
}

.card-item .card-content {
  width: calc(100% - 200px) !important;
}

.material-icons-round {
  width: 30px;
}

.cl-22 {
  color: #222 !important;
}

.mw-185px {
  min-width: 185px;
}

.btn-edit-img {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  color: #f44335;
  font-size: 14px;
  right: 30px;
  top: 4px;
  background: #ffffff;
  border-radius: 50%;
}

.schedule-list {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden !important;
}

.schedule-list::-webkit-scrollbar {
  width: 7px;
}

.cate-list > div:first-child > div::-webkit-scrollbar {
  height: 7px;
}

.schedule-list::-webkit-scrollbar-track,
.cate-list > div:first-child > div::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.schedule-list::-webkit-scrollbar-thumb,
.cate-list > div:first-child > div::-webkit-scrollbar-thumb {
  background: #888;
}

.schedule-list::-webkit-scrollbar-thumb:hover,
.cate-list > div:first-child > div::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.dropdown-menu {
  border: 1px solid #d5d6d8;
  min-width: 9rem;
}
.cursor-pointer {
  cursor: pointer !important;
}

.unset-ov {
  overflow: unset !important;
}

.table-responsive thead tr th {
  padding: 0.5rem !important;
}

.activeLayout {
  background: white;
  align-items: center !important;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
  padding: 6px;
  cursor: pointer;
}

.inActiveLayout {
  /* background: gray; */
  align-items: center !important;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
  padding: 6px;
  cursor: pointer;
}

.tag-a-custom {
  color: #007bff !important;
  text-decoration: underline !important;
  cursor: pointer;
}

.s-menuset .multiselect {
  margin: 0 !important;
  max-width: 500px !important;
}

.page-header .container {
  z-index: unset !important;
}

.page-header .mask {
  z-index: -1 !important;
}

.m-left-menu {
  width: 300px !important;
  min-width: 300px !important;
  margin-right: 24px;
}

.m-right-menu {
  width: 100%;
}
.hovered {
  background-color: #f5f5f5;
}

#general-tab .nav-item {
    width: 25%;
    text-align: center;
}

.selected {
  background-color: #f5f5f5;
}

.dropdownMenuButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
}
</style>
