import { createRouter, createWebHistory } from "vue-router";
import SignIn from "../views/SignIn.vue";
import Dashboard from "../views/Dashboard.vue";
import Home from "../views/Home.vue";
import Test from "../views/Test.vue";
import ResetPassword from "../views/ResetPassword.vue"
import SettingDiscount from "../views/setting/Discount.vue";
import DiscountList from "../views/setting/discounts/DiscountList.vue";
import DiscountDetail from "../views/setting/discounts/DiscountDetail.vue";
import VoucherList from "../views/setting/vouchers/VoucherList.vue";
import VoucherDetail from "../views/setting/vouchers/VoucherDetail.vue";
import VoucherBundleList from "../views/setting/vouchers/VoucherBundleList.vue";
import VoucherBundleDetail from "../views/setting/vouchers/VoucherBundleDetail.vue";
import StoreList from "../views/setting/stores/StoreList.vue";
import StoreDetail from "../views/setting/stores/StoreDetail.vue";
import SettingGeneral from "../views/setting/General.vue";
import SettingCashback from "../views/setting/Cashback.vue";
import PointCashback from "../views/setting/Point.vue";
import SettingTier from "../views/setting/Tier.vue";
import TopUpListSetting from "../views/setting/topup/TopUpList.vue";
import CampaignList from "../views/campaigns/CampaignList.vue";
import CampaignDetail from "../views/campaigns/CampaignDetail.vue";
import ProductList from "../views/products/List.vue";
import ProductDetail from "../views/products/ProductDetail.vue";
import SettingSlide from "../views/setting/Slide.vue";
import MemberList from "../views/members/MemberList.vue"
import MemberDetail from "../views/members/MemberDetail.vue"
import ProductGroup from "../views/products/Group.vue";
import PaymentList from "../views/payments/PaymentList";
import SubscriptionList from "../views/subscription/SubscriptionList";
import ViewReportMonthSubscriptionList from "../views/subscription/ViewReportMonthSubscriptionList";
import ViewReportMonthSubscriptionInvoiceList from "../views/subscription/ViewReportMonthSubscriptionInvoiceList";
import DashboardReportList from "../views/dashboardreports/DashboardReportList.vue";
import SlideShowReport from "../views/dashboardreports/SlideShowReport.vue";
import TopUpReport from "../views/dashboardreports/TopUpReport.vue";
import VoidTopUpReport from "../views/dashboardreports/VoidTopUpReport.vue";
import PosTransactionReport from "../views/dashboardreports/PosTransactionReport.vue";
import PaidMembershipReport from "../views/dashboardreports/PaidMembershipReport.vue";
import OnlineOrderingReport from "../views/dashboardreports/OnlineOrderingReport.vue";
import SmsSetup from "../views/setting/sms/SmsSetup.vue";
import EmailSetup from "../views/setting/email/EmailSetup.vue";
import CreateEmailSetup from "../views/setting/email/CreateEmail.vue";
import SmsPaymentHistory from "../views/setting/sms/SmsPaymentHistory.vue";
import checkModule from './middleware';
import MenuSet from "../views/menu/MenuSet.vue";
import MenuSetList from "../views/menu/MenuSetList.vue";
import CreateMenu from "../views/menu/CreateMenu.vue";
import ItemList from "../views/menu/ItemList.vue";
import CreateItem from "../views/menu/CreateItem.vue";
import CreateCombo from "../views/menu/CreateCombo.vue";
import ItemListByBranch from "../views/menu/ItemListByBranch.vue";
import ReportGroup from "../views/menu/ReportGroup.vue";
import BulkVoucherIssuanceList from "../views/bulkvoucher/BulkVoucherIssuanceList.vue";
import BulkVoucherIssuanceGenerateVoucher from "../views/bulkvoucher/BulkVoucherIssuanceGenerateVoucher.vue";
import OrderItemReport from "../views/dashboardreports/OrderItemReport.vue";
import WalletReport from "../views/dashboardreports/WalletReport.vue";

const routes = [
  {
    path: "/home",
    name: "Home",
    component: Home,
    beforeEnter: checkModule
  },
  {
    path: "/test",
    name: "Test",
    component: Test,
    beforeEnter: checkModule
  },
  {
    path: "/sign-in",
    name: "SignIn",
    component: SignIn,
  },
  {
    path: "/reset-password/:token?",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/dashboard-reports",
    name: "DashboardReportList",
    component: DashboardReportList,
    beforeEnter: checkModule
  },
  {
    path: "/general-setting",
    name: "SettingGeneral",
    component: SettingGeneral,
    beforeEnter: checkModule
  },
  {
    path: "/cashback-setting",
    name: "SettingCashback",
    component: SettingCashback,
    beforeEnter: checkModule
  },
  {
    path: "/point-setting",
    name: "SettingPoint",
    component: PointCashback,
    beforeEnter: checkModule
  },
  {
    path: "/discount-setting",
    name: "SettingDiscount",
    component: SettingDiscount,
  },
  {
    path: "/discounts",
    name: "DiscountList",
    component: DiscountList,
    beforeEnter: checkModule
  },
  {
    path: "/discount/:id?",
    name: "DiscountDetail",
    component: DiscountDetail
  },
  {
    path: "/vouchers",
    name: "VoucherList",
    component: VoucherList,
    beforeEnter: checkModule
  },
  {
    path: "/bulk-voucher-issuance",
    name: "BulkVoucherIssuance",
    component: BulkVoucherIssuanceList,
    beforeEnter: checkModule
  },
  {
    path: "/bulk-voucher-issuance-generate-voucher/:id",
    name: "BulkVoucherIssuanceGenerateVoucher",
    component: BulkVoucherIssuanceGenerateVoucher,
    beforeEnter: checkModule
  },
  {
    path: "/voucher/:id?",
    name: "VoucherDetail",
    component: VoucherDetail
  },
  {
    path: "/vouchers-bundle",
    name: "VoucherBundleList",
    component: VoucherBundleList,
    beforeEnter: checkModule
  },
  {
    path: "/voucher-bundle/:id?",
    name: "VoucherBundleDetail",
    component: VoucherBundleDetail
  },
  {
    path: "/branches",
    name: "StoreList",
    component: StoreList,
    beforeEnter: checkModule
  },
  {
    path: "/branch/:id?",
    name: "StoreDetail",
    component: StoreDetail
  },
  {
    path: "/top-up",
    name: "TopUpListSetting",
    component: TopUpListSetting,
    beforeEnter: checkModule
  },
  {
    path: "/tier-setting",
    name: "SettingTier",
    component: SettingTier,
    beforeEnter: checkModule
  },
  {
    path: "/slide-setting",
    name: "SettingSlide",
    component: SettingSlide,
    beforeEnter: checkModule
  },
  {
    path: "/campaigns",
    name: "CampaignList",
    component: CampaignList,
    beforeEnter: checkModule
  },
  {
    path: "/campaign/:id?",
    name: "CampaignDetail",
    component: CampaignDetail
  },
  {
    path: "/menu-set",
    name: "MenuSet",
    component: MenuSet,
  },
  {
    path: "/menu-set/list",
    name: "MenuSet List",
    component: MenuSetList,
  },
  {
    path: "/menu-set/create",
    name: "Create Menu",
    component: CreateMenu,
  },
  {
    path: "/menu-set/detail/:id",
    name: "Detail Menu",
    component: CreateMenu,
  },
  {
    path: "/create-item",
    name: "Create Item",
    component: CreateItem,
  },
  {
    path: "/edit-item/:id",
    name: "Edit Item",
    component: CreateItem,
  },
  {
    path: "/create-combo",
    name: "Create Combo",
    component: CreateCombo,
  },
  {
    path: "/edit-combo/:id",
    name: "Edit Combo",
    component: CreateCombo,
  },
  {
    path: "/products",
    name: "ProductList",
    component: ProductList,
    beforeEnter: checkModule
  },
  {
    path: "/product/:id?",
    name: "ProductDetail",
    component: ProductDetail
  },
  {
    path: "/members",
    name: "MemberList",
    component: MemberList,
    beforeEnter: checkModule
  },
  {
    path: "/member/:id?",
    name: "MemberDetail",
    component: MemberDetail
  },
  {
    path: "/group",
    name: "ProductGroup",
    component: ProductGroup,
    beforeEnter: checkModule
  },
  {
    path: "/payments",
    name: "PaymentList",
    component: PaymentList,
    beforeEnter: checkModule
  },
  {
    path: "/subscriptions",
    name: "SubscriptionList",
    component: SubscriptionList,
    beforeEnter: checkModule
  },
  {
    path: "/view-report-month-subscriptions",
    name: "ViewReportMonthSubscriptionList",
    component: ViewReportMonthSubscriptionList,
  },
  {
    path: "/view-report-subscriptions-invoice/:id?",
    name: "ViewReportMonthSubscriptionInvoiceList",
    component: ViewReportMonthSubscriptionInvoiceList,
  },
  {
    path: "/dashboard-reports-slides-show",
    name: "DashboardReportSlidesShow",
    component: SlideShowReport,
    beforeEnter: checkModule
  },
  {
    path: "/dashboard-reports-top-up",
    name: "DashboardReportTopUp",
    component: TopUpReport,
    beforeEnter: checkModule
  },
  {
    path: "/dashboard-reports-void-top-up",
    name: "DashboardReportVoidTopup ",
    component: VoidTopUpReport,
    beforeEnter: checkModule
  },
  {
    path: "/dashboard-reports-pos-transaction",
    name: "DashboardReportPosTransaction",
    component: PosTransactionReport,
    beforeEnter: checkModule
  },
  {
    path: "/dashboard-reports-paid-membership",
    name: "DashboardReportPaidMembership",
    component: PaidMembershipReport,
    beforeEnter: checkModule
  },
  {
    path: "/dashboard-reports-online-ordering",
    name: "DashboardReportOnlineOrdering",
    component: OnlineOrderingReport,
    beforeEnter: checkModule
  },
  {
    path: "/dashboard-reports-wallet",
    name: "WalletReport",
    component: WalletReport,
    beforeEnter: checkModule
  },
  {
    path: "/sms-setup",
    name: "SmsSetup",
    component: SmsSetup,
    beforeEnter: checkModule
  },
  {
    path: "/sms-payment-history",
    name: "SmsPaymentHistory",
    component: SmsPaymentHistory,
  },
  {
    path: "/item-list",
    name: "ItemList",
    component: ItemList,
  },
  {
    path: "/item-list-by-branch",
    name: "ItemListByBranch",
    component: ItemListByBranch,
  },
  {
    path: "/report-group",
    name: "ReportGroup",
    component: ReportGroup,
  },
  {
    path: "/email-setup",
    name: "EmailSetup",
    component: EmailSetup,
    beforeEnter: checkModule
  },
  {
    path: "/email-setup/create",
    name: "CreateEmailSetup",
    component: CreateEmailSetup,
    beforeEnter: checkModule
  },
  {
    path: "/email-setup/detail/:id",
    name: "EditEmailSetup",
    component: CreateEmailSetup,
    beforeEnter: checkModule
  },
  {
    path: "/dashboard-reports-order-item",
    name: "DashboardReportOrderItem",
    component: OrderItemReport,
    beforeEnter: checkModule
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to) => {
  console.log(to)
})

export default router;
