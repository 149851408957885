<template>
  <div>
    <div class="row mb-2 pe-4">
      <div class="col-lg-8 col-md-7 col-7 d-flex align-items-center">
        <div class="color-common">
          <h3 class="title-page mb-0 ps-3">
            {{ itemIdEdit ? "Edit Item" : "Create Item" }}
          </h3>
        </div>
      </div>
    </div>
    <div class="page-header align-items-start min-vh-100" v-if="isLoadData">
      <span class="mask bg-white"></span>
      <div class="container">
        <div class="row justify-content-center mt-5">
          <div class="col-xl-10 col-12 mx-auto">
            <div class="row">
              <div class="col-6 col-mg">
                <label class="fw-bold" for>
                  Item No
                  <span class="text-danger ms-2">*</span>
                </label>
                <material-input
                  v-model="item.item_no"
                  type="text"
                  id="item_no"
                  name="item_no"
                />
                <span
                  class="is-invalid"
                  v-if="fieldRequired.isItemNameNoRequired"
                  >The field is required.</span
                >
              </div>
              <div class="col-6 col-mg">
                <label class="fw-bold" for>
                  Item Name
                  <span class="text-danger ms-2">*</span>
                </label>
                <material-input
                  v-model="item.item_name"
                  type="text"
                  id="item_name"
                  name="item_name"
                />
                <span class="is-invalid" v-if="fieldRequired.isItemNameRequired"
                  >The field is required.</span
                >
              </div>
            </div>
            <div class="row">
              <div class="col-6 col-mg">
                <label class="fw-bold" for>
                  Item Type
                  <span class="text-danger ms-2">*</span>
                </label>
                <Multiselect
                  v-model="item.item_type"
                  :options="itemTypes"
                  placeholder="Select item type"
                  :canClear="false"
                />
              </div>
              <div class="col-6 col-mg">
                <label class="fw-bold" for> Item Price </label>
                <material-input
                  @keydown.prevent.up="preventUpDown"
                  @keydown.prevent.down="preventUpDown"
                  v-model="item.price"
                  id="item_price"
                  name="item_price"
                  extraClass="p-2"
                  type="number"
                  size="lg"
                />
                <span
                  class="is-invalid"
                  v-if="fieldRequired.isItemPriceRequired"
                  >Number must be greater than or equal to 0.</span
                >
              </div>
            </div>
            <div class="row">
              <div class="col-6 col-mg">
                <label class="fw-bold" for>Description</label>
                <material-textarea
                  rows="12"
                  v-model="item.description"
                  id="item_description"
                  name="item_description"
                />
              </div>
              <div class="col-6 col-mg">
                <label class="fw-bold" for>Image</label>
                <div class="dowload-img" v-show="urlImagePreview">
                  <div id="preview" style="position: relative">
                    <img
                      class="image-url"
                      v-if="urlImagePreview"
                      :src="urlImagePreview"
                    />
                    <i
                      class="fa fa-times icon-close-sidenav-main danger"
                      v-if="urlImagePreview"
                      style="
                        position: absolute;
                        color: #f44335;
                        font-size: 24px;
                        right: 5px;
                        top: 6px;
                      "
                      aria-hidden="true"
                      @click="clearImage()"
                    ></i>
                    <div class="btn-edit-img">
                      <i
                        class="fas fa-pencil-alt text-dark"
                        v-if="urlImagePreview"
                        aria-hidden="true"
                        @click="uploadFile('image-item')"
                      ></i>
                    </div>
                  </div>
                </div>
                <div
                  class="dowload-img"
                  @click="uploadFile('image-item')"
                  v-show="!urlImagePreview"
                >
                  <material-input
                    style="display: none"
                    type="file"
                    id="image-item"
                    @change="handleFileUpload($event)"
                    accept="image/*"
                  />
                  <i class="material-icons css-icon-upload">backup</i>
                  <h6>Click here to upload an image.</h6>
                </div>
                <div
                  class="is-invalid d-flex align-items-center pe-2"
                  v-if="fieldRequired.isImageRequire"
                >
                  {{ messageImageError }}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6 col-mg">
                <label class="fw-bold" for> Pos Name </label>
                <material-input
                  v-model="item.pos_name"
                  type="text"
                  id="pos_name"
                  name="pos_name"
                />
              </div>
              <div class="col-6 col-mg">
                <label class="fw-bold" for> Pos Description </label>
                <material-input
                  v-model="item.pos_description"
                  type="text"
                  id="pos_description"
                  name="pos_description"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-6 col-mg">
                <label class="fw-bold" for> Button Color </label>
                <div>
                  <color-input
                    v-model="item.button_color"
                    format="hex"
                    position="right bottom"
                  />
                </div>
              </div>
              <div class="col-6 col-mg">
                <label class="fw-bold" for> Font Color </label>
                <div>
                  <color-input
                    v-model="item.font_color"
                    format="hex"
                    position="right bottom"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6 col-mg">
                <label class="fw-bold" for> Button Position </label>
                <div class="d-flex">
                  <material-input
                    @keydown.prevent.up="preventUpDown"
                    @keydown.prevent.down="preventUpDown"
                    v-model="item.button_position"
                    id="button_position"
                    name="button_position"
                    extraClass="p-2"
                    type="number"
                    size="lg"
                  />
                </div>
                <span
                  class="is-invalid"
                  v-if="fieldRequired.isItemButtonPositionRequired"
                  >Number must be greater than or equal to 0.</span
                >
              </div>
              <div class="col-6 col-mg"></div>
            </div>
            <div class="row align-items-center">
              <div class="col-lg-7 col-12 col-mg">
                <label class="fw-bold" for>Report group</label>
                <div class="d-flex">
                  <Multiselect
                    v-model="item.type_id"
                    :options="types"
                    :canClear="false"
                    placeholder="Type"
                    @change="selectType"
                  />
                  <Multiselect
                    v-model="item.group_id"
                    :options="groups"
                    :canClear="false"
                    class="ms-2"
                    placeholder="Group"
                    :disabled="!item.type_id"
                  />
                </div>
              </div>
              <div class="col-lg-5 col-12 text-end mt-3">
                <a
                  @click="this.$router.push('/report-group')"
                  class="text-decoration-underline tag-a-custom"
                  >Manage Report Type & Group</a
                >
              </div>
            </div>
            <div class="row">
              <div class="col-6 col-mg">
                <label class="fw-bold" for>Min order limit</label>
                <div class="d-flex">
                  <material-input
                    @keydown.prevent.up="preventUpDown"
                    @keydown.prevent.down="preventUpDown"
                    v-model="item.min_order_qty"
                    id="min_order_qty"
                    name="min_order_qty"
                    extraClass="p-2"
                    type="number"
                    size="lg"
                  />
                </div>
                <span
                  class="is-invalid"
                  v-if="fieldRequired.isItemMinOrderLimitRequired"
                  >Number must be greater than or equal to 0.</span
                >
              </div>
              <div class="col-6 col-mg">
                <label class="fw-bold" for>Max order limit</label>
                <div class="d-flex">
                  <material-input
                    @keydown.prevent.up="preventUpDown"
                    @keydown.prevent.down="preventUpDown"
                    v-model="item.max_order_qty"
                    id="max_order_qty"
                    name="max_order_qty"
                    extraClass="p-2"
                    type="number"
                    size="lg"
                  />
                </div>
                <span
                  class="is-invalid"
                  v-if="fieldRequired.isItemMinOrderLimitRequired"
                  >Number must be greater than or equal to 0.</span
                >
              </div>
            </div>
            <div class="row">
              <div class="col-6 col-mg">
                <label class="fw-bold" for>Buy one get one free</label>
                <div class="d-flex">
                  <div
                    :class="{ active: item.is_buy_one_get_one_free }"
                    class="toggle_container pt-1"
                  >
                    <MaterialToggleButton
                      labelEnableText
                      labelDisableText
                      id="event_handle"
                      v-on:change="triggerEventFreeItem"
                      class="mb-0"
                    />
                  </div>
                </div>
              </div>
              <div class="col-6 col-mg">
                <label class="fw-bold" for>Disable item</label>
                <div class="d-flex">
                  <div
                    :class="{ active: item.is_disabled }"
                    class="toggle_container pt-1"
                  >
                    <MaterialToggleButton
                      labelEnableText
                      labelDisableText
                      id="event_handle_2"
                      v-on:change="triggerEvent"
                      class="mb-0"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6 col-mg">
                <label class="fw-bold" for>Tags & allergens</label>
                <Multiselect
                  :createOption="true"
                  :searchable="true"
                  :mode="'tags'"
                  v-model="item.tags"
                  :options="tagList"
                  :canClear="false"
                  placeholder="Enter some Tags & allergens"
                />
              </div>
            </div>
            <div class="price-box">
              <h3 class="title-page fs-16 mb-0">PRICE LIST</h3>
              <div class="d-flex justify-content-end mt-3">
                <div class="input-group mw-300px">
                  <div class="form-outline w-100" style="width: calc(100% - 75px) !important;">
                    <input
                      type="text"
                      name="variation_name"
                      id="variation_name"
                      class="form-control"
                      style="
                        border: 1px solid #d2d6da;
                        padding: 8px !important;
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                      "
                      placeholder="Name the Variation"
                      @keydown.enter="addVariation()"
                    />
                  </div>
                  <div
                    type="button"
                    class="btn mb-0"
                    style="border: 1px solid #d2d6da"
                    @click="addVariation()"
                  >
                    Add
                  </div>
                </div>
                <span class="is-invalid d-none" id="variation_name_error"
                  >The field is required.</span
                >
              </div>
              <div class="price-list">
                <div
                  class="table-responsive p-0 mt-3 table-variation"
                  v-if="item.variations.length"
                >
                  <table
                    class="table align-items-center mb-0 table-variation-price"
                  >
                    <thead>
                      <tr>
                        <th>Variation</th>
                        <th>Variation No</th>
                        <th>Description</th>
                        <th>Image</th>
                        <template
                          v-for="(price, index) in item.variations[0].prices"
                          :key="index"
                        >
                          <th class="variation-price">
                              {{ price.name }}<br>
                              <small>{{ priceList[index].description ?? "" }}</small>
                          </th>
                        </template>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <template
                        v-for="(variation, index) in item.variations"
                        :key="index"
                      >
                        <tr>
                          <td class="tex-end">
                            <material-input
                              v-model="variation.name"
                              type="text"
                              :id="'variation_name_' + index"
                              :name="'variation_name_' + index"
                            />
                          </td>
                          <td class="tex-end">
                            <material-input
                              v-model="variation.variation_no"
                              type="text"
                              :id="'variation_no_' + index"
                              :name="'variation_no_' + index"
                            />
                          </td>
                          <td class="tex-end">
                            <material-input
                              v-model="variation.description"
                              type="text"
                              :id="'variation_option_description_' + index"
                              :name="'variation_option_description_' + index"
                            />
                          </td>
                          <td class="tex-end">
                            <div>
                              <div
                                class="dowload-img variation-option-dowload-img"
                                v-show="variation.image_preview"
                              >
                                <div id="preview" style="position: relative">
                                  <img
                                    class="image-url"
                                    v-if="variation.image_preview"
                                    :src="variation.image_preview"
                                  />
                                  <div class="btn-edit-img">
                                    <i
                                      class="fas fa-pencil-alt text-dark"
                                      v-if="variation.image_preview"
                                      aria-hidden="true"
                                      @click="
                                        uploadFile(
                                          'variation-option-image-' + index
                                        )
                                      "
                                    ></i>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="dowload-img variation-option-dowload-img"
                                @click="
                                  uploadFile('variation-option-image-' + index)
                                "
                                v-show="!variation.image_preview"
                              >
                                <material-input
                                  style="display: none"
                                  type="file"
                                  :id="'variation-option-image-' + index"
                                  @change="handleFileUpload($event, index)"
                                  accept="image/*"
                                />
                                <i class="material-icons css-icon-upload"
                                  >backup</i
                                >
                              </div>
                            </div>
                          </td>
                          <template
                            v-for="(price, index2) in variation.prices"
                            :key="index2"
                          >
                            <td class="tex-end">
                              <material-input
                                @keydown.prevent.up="preventUpDown"
                                @keydown.prevent.down="preventUpDown"
                                v-model="price.price"
                                :id="
                                  'variation_option_price_' +
                                  index +
                                  '' +
                                  index2
                                "
                                :name="
                                  'variation_option_price_' +
                                  index +
                                  '' +
                                  index2
                                "
                                extraClass="p-2"
                                type="number"
                                size="lg"
                              />
                            </td>
                          </template>
                          <td>
                            <div class="d-flex">
                              <div
                                :class="{ active: variation.status }"
                                class="toggle_container pt-1"
                              >
                                <MaterialToggleButton
                                  labelEnableText
                                  labelDisableText
                                  :id="'variation-price-status-' + index"
                                  v-on:change="triggerVariation(index)"
                                  class="mb-0"
                                />
                              </div>
                              <div class="ms-3 d-flex align-items-center">
                                <i
                                  class="fas fa-trash-alt cursor-pointer ms-2"
                                  @click="confirmDelete(index)"
                                ></i>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <div
                          class="is-invalid"
                          v-if="
                            fieldVariationRequired[index] &&
                            fieldVariationRequired[index].isImageRequire
                          "
                        >
                          {{ messageImageError }}
                        </div>
                      </template>
                    </tbody>
                  </table>
                </div>
                <div class="table-responsive p-0 mt-3" v-else>
                  <table class="table align-items-center mb-0">
                    <thead>
                      <tr>
                        <th class="w-30">Label</th>
                        <th class="w-35">Description</th>
                        <th class="w-20">Price</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(price, index) in item.others_price"
                        :key="index"
                      >
                        <td class="tex-end">{{ price.name }}</td>
                        <td class="tex-end">
                          <material-input
                            v-model="price.description"
                            type="text"
                            :id="'price_description_' + index"
                            :name="'price_description_' + index"
                          />
                        </td>
                        <td class="tex-end">
                          <material-input
                            @keydown.prevent.up="preventUpDown"
                            @keydown.prevent.down="preventUpDown"
                            v-model="price.price"
                            :id="'other_price_' + index"
                            :name="'other_price_' + index"
                            extraClass="p-2"
                            type="number"
                            size="lg"
                          />
                        </td>
                        <td>
                          <div class="d-flex">
                            <div
                              :class="{ active: price.status }"
                              class="toggle_container pt-1"
                            >
                              <MaterialToggleButton
                                labelEnableText
                                labelDisableText
                                :id="'price-status-' + index"
                                v-on:change="triggerEventPrice(index)"
                                class="mb-0"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="modifier-box mt-3">
              <div class="d-flex align-items-center">
                <h3 class="title-page fs-16 mb-0">MODIFIER</h3>
                <material-button
                  class="ms-3 btn btn-sm btn-background"
                  @click="addOptionGroup()"
                  >Add Option Group</material-button
                >
              </div>
              <div v-for="(group, index) in groupsSelect" :key="index">
                <div class="title-page fs-16 mt-4">
                  {{ "OPTION GROUP - " + group.name.toUpperCase() }}
                </div>
                <div
                  v-for="(section, index2) in group.modifier_option_group_section"
                  :key="index2"
                >
                  <div class="title-page fs-16 mt-3">
                    {{ section.title }}
                  </div>
                  <div class="d-flex justify-content-between mt-3">
                    <div>Min {{ group.min_qty }}</div>
                    <div>Max {{ group.max_qty }}</div>
                    <div>Item Max {{ group.item_max_qty }}</div>
                    <div>
                      Dining Option:
                      {{ diningOptionLabel[group.dining_option - 1] }}
                    </div>
                    <div class="d-flex">
                      Remark
                      <material-checkbox
                        :checked="section.is_remark"
                        class="align-items-center checkbox-custom mb-0 ms-3"
                        :isDisabled="true"
                      ></material-checkbox>
                    </div>
                    <div class="d-flex">
                      Required
                      <material-checkbox
                        :checked="section.is_required"
                        class="align-items-center checkbox-custom mb-0 ms-3"
                        :isDisabled="true"
                      ></material-checkbox>
                    </div>
                  </div>
                  <div class="modifier-list">
                    <div class="table-responsive p-0 mt-3">
                      <table class="table align-items-center mb-0">
                        <thead>
                          <tr>
                            <th>Code</th>
                            <th>Name</th>
                            <th>Image</th>
                            <th>Price</th>
                            <th>Display</th>
                          </tr>
                        </thead>
                        <tbody v-if="section.items.length">
                          <tr v-for="(item, index3) in section.items" :key="index3">
                            <td class="tex-end">{{ item.item_no_format }}</td>
                            <td class="tex-end">{{ item.item_name_format }}</td>
                            <td>
                              <img :src="item.image" width="70" height="70" />
                            </td>
                            <td class="tex-end">{{ item.item_price_format }}</td>
                            <td>
                              <div class="d-flex">
                                <div
                                  :class="{ active: item.modifier_status }"
                                  class="toggle_container pt-1"
                                >
                                  <MaterialToggleButton
                                    labelEnableText
                                    labelDisableText
                                    :id="'modifier-status-' + index + '_' + index2 + '_' + index3"
                                    v-on:change="
                                      triggerEventAppliedPrice(index, index2, index3)
                                    "
                                    class="mb-0"
                                  />
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <td colspan="5" class="text-center">
                              No item available
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="schedule-box" v-if="itemIdEdit">
              <div class="d-flex align-items-center">
                <h3 class="title-page fs-16 mb-0">SCHEDULE</h3>
                <material-button
                  class="ms-3 btn btn-sm btn-background"
                  @click="addSchedule()"
                  >Edit Schedule</material-button
                >
              </div>
              <div class="schedule-list mt-3">
                <div v-for="(schedule, index) in scheduleList" :key="index">
                  <div class="mt-2">
                    <div>
                      {{ rangeTime(schedule) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-5 mb-5" style="float: right">
              <material-button
                @click="saveItem()"
                class="mx-3 btn btn-sm btn-background"
                >Save</material-button
              >
            </div>
          </div>
        </div>
      </div>
      <material-loading :active="loaderActive" />
      <ModalMenuSetSchedule
        :keyReload="keyReloadScheduleModal"
        @reload-schedule="reloadSchedule"
        :setIdSelect="setId"
        :branchIdSelectEdit="branchIdSelectEdit"
        :categoryIdSelect="categoryId"
        :itemIdSelect="itemIdEdit"
      ></ModalMenuSetSchedule>
      <AddOptionGroup
        :keyReload="keyReloadModalAddOptionGroup"
        :setId="setId"
        :itemId="itemIdEdit"
        :groupIdsSelect="groupIdsSelect"
        :groupsSelect="groupsSelect"
        @handle-add-option-group="handleAddOptionGroup"
      ></AddOptionGroup>
      <ModalConfirm
        message="Are you sure you want to delete?"
        :process-yes="removeVariation"
      />
    </div>
    <div class="position-fixed top-1 end-1 z-index-2 message-success">
      <material-snackbar
        v-if="snackbar === 'danger' || snackbar === 'success'"
        title
        date
        :description="message"
        :icon="{ component: 'campaign', color: 'white' }"
        :color="snackbar"
        :close-handler="closeSnackbar"
      />
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialTextarea from "@/components/MaterialTextarea.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import "@vueform/multiselect/themes/default.css";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import MaterialToggleButton from "@/components/MaterialToggleButton.vue";
import ItemService from "../../services/product/item.service";
import MenusetService from "../../services/product/menuset.service";
import ScheduleService from "../../services/product/schedule.service";
import MaterialLoading from "@/components/MaterialLoading.vue";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import ModalConfirm from "@/components/ModalConfirm.vue";
import ColorInput from "vue-color-input";
import ModalMenuSetSchedule from "@/components/ModalMenuSetSchedule.vue";
import AddOptionGroup from "@/components/AddOptionGroup.vue";

export default {
  name: "create-menu",
  components: {
    MaterialSnackbar,
    MaterialInput,
    MaterialTextarea,
    MaterialButton,
    Multiselect,
    MaterialToggleButton,
    MaterialLoading,
    MaterialCheckbox,
    ColorInput,
    ModalConfirm,
    ModalMenuSetSchedule,
    AddOptionGroup,
  },
  props: {
    isUpdate: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isLoadData: false,
      snackbar: null,
      message: "",
      urlImagePreview: null,
      messageImageError: "",
      fieldRequired: [],
      isActive: true,
      checkArrow: false,
      checkArrowTable: false,
      loaderActive: false,
      categoryId: this.$route.query.category_id ?? 0,
      groupId: this.$route.query.group_id ?? 0,
      subGroupId: this.$route.query.sub_group_id ?? 0,
      setId: this.$route.query.set_id ?? 0,
      itemIdEdit: this.$route.params.id ?? 0,
      priceList: [],
      scheduleList: [],
      branchIdSelectDelete: 0,
      variationIndexSelectDelete: 0,
      keyReloadScheduleModal: "key-reload-schedule-modal",
      keyReloadModalAddOptionGroup: "key-reload-modal-add-option-group",
      groupIdsSelect: [],
      groupsSelect: [],
      groups: [],
      dataGroupByType: [],
      types: [],
      tags: [
        {
          label: "Cheese",
          value: 1,
        },
        {
          label: "Seafood",
          value: 2,
        },
      ],
      itemTypes: [
        {
          label: "Fixed price",
          value: 1,
        },
        {
          label: "Bundle",
          value: 2,
        },
        {
          label: "Open Price",
          value: 3,
        },
        {
          label: "By Weight (per KG)",
          value: 4,
        },
        {
          label: "Sub Category (Only POS)",
          value: 5,
        },
        {
          label: "Charge %",
          value: 6,
        },
        {
          label: "Discount $",
          value: 7,
        },
        {
          label: "Discount %",
          value: 8,
        },
        {
          label: "Modifier",
          value: 9,
        },
      ],
      diningOptionLabel: ["All", "Dine in", "Takeaway"],
      item: {
        item_no: "",
        item_name: "",
        image: null,
        description: "",
        pos_name: "",
        pos_description: "",
        barcode: "",
        price: 0,
        min_order_qty: 0,
        max_order_qty: 1,
        is_disabled: false,
        item_type: 1,
        group_id: 0,
        type_id: 0,
        button_color: "#82001e",
        font_color: "#82001e",
        button_position: 0,
        others_price: [],
        delivery_tax: 0,
        takeaway_tax: 0,
        eat_in_tax: 0,
        category_id: this.$route.query.category_id ?? 0,
        menuset_id: this.$route.query.set_id ?? 0,
        group_item_id: this.$route.query.group_id ?? 0,
        sub_group_item_id: this.$route.query.sub_group_id ?? 0,
        is_buy_one_get_one_free: false,
        variations: [],
        tag: 0,
        tags: [],
        variationIdDelete: [],
        modifier_option_group_ids: [],
      },
      categories: [],
      fieldVariationRequired: [],
      isEditVariation: false,
      tagList: []
    };
  },
  async created() {
    await this.getPriceList();
    await this.getReportGroup();
    await this.getListTag();
    if (this.itemIdEdit && this.isLoadData) {
      await this.getItemDetail();
      if (this.isLoadData) {
        await this.getScheduleInfo();
      }
    }
  },
  methods: {
    handleChangeTag(tags) {
      this.item.tags = tags;
    },
    uploadFile(id) {
      $("#" + id).click();
    },

    clearImage() {
      this.urlImagePreview = null;
      this.fieldRequired.isImageRequire = false;
    },

    handleFileUpload(event, index = -1) {
      let file = event.target.files[0];
      if (file != null || file != undefined) {
        if (index >= 0) {
          this.item.variations[index].image = null;
          this.item.variations[index].image_preview = null;
        } else {
          this.item.image = null;
          this.urlImagePreview = null;
        }
        if (
          !["image/png", "image/jpg", "image/jpeg", "image/svg"].includes(
            file.type
          )
        ) {
          if (index >= 0) {
            this.fieldVariationRequired[index].isImageRequire = true;
          } else {
            this.fieldRequired.isImageRequire = true;
          }
          this.messageImageError = `File must be an image.`;
        } else {
          if (index >= 0) {
            this.item.variations[index].image = file;
            this.item.variations[index].image_preview = URL.createObjectURL(
              file
            );
          } else {
            this.item.image = file;
            this.urlImagePreview = URL.createObjectURL(file);
          }
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (evt) => {
            let img = new Image();
            img.onload = () => {
              let width = img.width;
              let height = img.height;
              if (width < 100 || height < 100) {
                this.messageImageError = `The image width ${width} x ${height} is too small.`;
                if (index >= 0) {
                  this.fieldVariationRequired[index].isImageRequire = true;
                } else {
                  this.fieldRequired.isImageRequire = true;
                }
              } else {
                if (index >= 0) {
                  this.fieldVariationRequired[
                    index
                  ].isImageRequire = false;
                } else {
                  this.fieldRequired.isImageRequire = false;
                }
                this.messageImageError = "";
              }
            };
            img.src = evt.target.result;
          };
        }
      } else {
        if (index >= 0) {
          this.item.variations[index].image = null;
          this.item.variations[index].image_preview = null;
        } else {
          this.item.image = null;
          this.urlImagePreview = null;
        }
        this.messageImageError = "";
      }
    },

    clearFormData() {
      this.item = {
        item_no: "",
        item_name: "",
        image: null,
        description: "",
        pos_name: "",
        pos_description: "",
        barcode: "",
        price: 0,
        min_order_qty: 0,
        max_order_qty: 1,
        is_disabled: false,
        item_type: 1,
        group_id: 0,
        type_id: 0,
        button_color: "#82001e",
        font_color: "#82001e",
        button_position: 0,
        others_price: this.priceList,
        delivery_tax: 0,
        takeaway_tax: 0,
        eat_in_tax: 0,
        category_id: this.categoryId,
        menuset_id: this.$route.query.set_id,
        group_item_id: this.$route.query.group_id ?? 0,
        sub_group_item_id: this.$route.query.sub_group_id ?? 0,
        is_buy_one_get_one_free: false,
        variations: [],
        modifier_option_group_ids: [],
        variationIdDelete: [],
      };
      this.urlImagePreview = false;
      $("#image-item").val("");
      this.snackbar = null;
      this.message = "";
    },

    saveItem() {
      if (this.processValidate()) {
        this.showLoader();
        this.item.modifier_option_groups = this.groupsSelect;
        if (this.item.id > 0) {
          // update
          ItemService.update(this.item.id, this.item).then(
            (response) => {
              if (response.data.result.isSuccess) {
                this.snackbar = "success";
                this.message = "Update Item Success";
                this.setTimeOutNotification();
                setTimeout(() => {
                  this.$router.push("/item-list");
                }, 1000);
              } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
              }
              this.hideLoader();
            },
            (error) => {
              this.snackbar = "danger";
              this.message = error.response.data.result.message;
              this.hideLoader();
              this.setTimeOutNotification();
            }
          );
        } else {
          // add
          ItemService.store(this.item).then(
            (response) => {
              if (response.data.result.isSuccess) {
                this.clearFormData();
                this.snackbar = "success";
                this.message = "Add Item Success";
                this.setTimeOutNotification();
                let url = "/menu-set?type=" + (this.$route.query.type ?? 1) +"&set_id=" + this.item.menuset_id;

                if (this.item.category_id) {
                  url += '&category_id=' + this.item.category_id
                }

                if (this.item.group_item_id) {
                  url += '&group_id=' + this.item.group_item_id
                }

                if (this.item.sub_group_item_id) {
                  url += '&sub_group_id=' + this.item.sub_group_item_id
                }

                setTimeout(() => {
                  this.$router.push(url);
                }, 1000);
              } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
              }
              this.hideLoader();
            },
            (error) => {
              this.snackbar = "danger";
              this.message = error.response.data.result.message;
              this.hideLoader();
              this.setTimeOutNotification();
            }
          );
        }
      }
    },

    async getListTag() {
      this.showLoader();
      await ItemService.getListTag().then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.tagList = response.data.data;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    async getReportGroup() {
      this.showLoader();
      await ItemService.getReportGroup().then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.types = response.data.data.dataTypeDropdown;
            this.dataGroupByType = response.data.data.dataGroupDropdown;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    selectType(value) {
      this.item.group_id = 0;
      this.groups = this.dataGroupByType[value];
    },

    async getScheduleInfo() {
      this.isLoadData = false;
      this.showLoader();
      var dataForm = {
        set_id: this.setId,
        category_id: this.categoryId,
        item_id: this.itemIdEdit,
      };

      await ScheduleService.getScheduleInfo(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.scheduleList = response.data.data;
            this.isLoadData = true;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    removeSchedule() {
      $(".btn-close-modal").click();
      this.showLoader();
      var dataForm = {
        set_id: this.setId,
        branch_id: this.branchIdSelectDelete,
        category_id: this.categoryId,
        item_id: this.itemIdEdit,
      };

      ScheduleService.removeSchedule(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.branchIdSelectDelete = 0;
            this.getScheduleInfo();
            this.snackbar = "success";
            this.message = "Delete Schedule Success";
            this.setTimeOutNotification();
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    reloadSchedule(mess) {
      this.snackbar = "success";
      this.message = mess;
      this.setTimeOutNotification();
      this.getScheduleInfo();
    },

    async getItemDetail() {
      this.isLoadData = false;
      this.showLoader();
      await ItemService.itemDetail(this.itemIdEdit).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.item = response.data.data;
            this.groups = this.dataGroupByType[this.item.type_id] ?? [];
            this.priceList = this.item.others_price;
            this.item.variations.forEach(() => {
              this.fieldVariationRequired.push({
                isNameRequire: false,
                isImageRequire: false,
              })
            })
            this.groupIdsSelect = this.item.modifier_option_group_ids;
            this.item.modifier_option_groups.forEach((group) => {
              if (group["modifier_option_group"]) {
                this.groupsSelect.push(group["modifier_option_group"]);
              }
            });
            this.urlImagePreview = this.item.image;
            this.isLoadData = true;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error.response.data.result.message;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    async getPriceList() {
      this.isLoadData = false;
      this.showLoader();
      var dataForm = {
        set_id: this.setId,
      };

      await MenusetService.getPriceList(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.priceList = response.data.data.price_list_display;
            this.item.others_price = this.priceList;
            this.isLoadData = true;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    resetRequired() {
      this.fieldRequired.isItemNameNoRequired = false;
      this.fieldRequired.isItemNameRequired = false;
      this.fieldRequired.isItemMinOrderLimitRequired = false;
      this.fieldRequired.isItemMaxOrderLimitRequired = false;
      this.fieldRequired.isItemPriceRequired = false;
    },

    processValidate() {
      this.resetRequired();
      var isValid = true;
      if (this.item.item_no == "" || this.item.item_no == null) {
        this.fieldRequired.isItemNameNoRequired = true;
        isValid = false;
      }

      if (this.item.item_name == "" || this.item.item_name == null) {
        this.fieldRequired.isItemNameRequired = true;
        isValid = false;
      }

      if (this.item.price === "" || this.item.price < 0) {
        this.fieldRequired.isItemPriceRequired = true;
        isValid = false;
      }

      if (this.item.min_order_qty === "" || this.item.min_order_qty < 0) {
        this.fieldRequired.isItemMinOrderLimitRequired = true;
        isValid = false;
      }

      if (this.item.max_order_qty === "" || this.item.max_order_qty < 0) {
        this.fieldRequired.isItemMaxOrderLimitRequired = true;
        isValid = false;
      }

      if (this.item.button_position === "" || this.item.button_position < 0) {
        this.fieldRequired.isItemButtonPositionRequired = true;
        isValid = false;
      }

      this.item.variations.map((variation, index) => {
        if (variation.name == "" || variation.name == null) {
          this.fieldVariationRequired[index].isNameRequire = true;
          isValid = false;
          return;
        }
      });

      return isValid;
    },

    rangeTime(schedule) {
      return (
        schedule.day +
        " " +
        this.formatDate(schedule.time_from) +
        " - " +
        this.formatDate(schedule.time_to)
      );
    },

    formatDate(date) {
      return new Date(date).toLocaleTimeString("fr-FR", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
    },

    addSchedule() {
      this.branchIdSelectEdit = 0;
      this.keyReloadScheduleModal = Math.random();
      $("#show-modal-menuset-schedule").click();
    },

    editSchedule(branchId) {
      this.branchIdSelectEdit = branchId;
      this.keyReloadScheduleModal = Math.random();
      $("#show-modal-menuset-schedule").click();
    },

    confirmDelete(index) {
      this.variationIndexSelectDelete = index;
      $(".btn-confirm-modal").click();
    },

    triggerEvent(value) {
      if (typeof value == "boolean") {
        this.item.is_disabled = !value;
      }
    },

    triggerEventFreeItem(value) {
      if (typeof value == "boolean") {
        this.item.is_buy_one_get_one_free = !value;
      }
    },

    triggerEventPrice(index) {
      var value = $("#price-status-" + index + "_button").is(":checked");
      if (typeof value == "boolean") {
        this.item.others_price[index].status = !value;
      }
    },

    triggerEventAppliedPrice(index, index2, index3) {
      var value = $("#modifier-status-" + index + "_" + index2 + "_" + index3 + "_button").is(
        ":checked"
      );
      if (typeof value == "boolean") {
        this.groupsSelect[index].modifier_option_group_section[index2].items[index3].modifier_status = !value;
      }
    },

    triggerVariation(index) {
      var value = $("#variation-price-status-" + index + "_button").is(
        ":checked"
      );
      if (typeof value == "boolean") {
        this.item.variations[index].status = !value;
      }
    },

    addOptionGroup() {
      this.keyReloadModalAddOptionGroup = Math.random();
      $("#show-modal-add-option-group").click();
    },

    handleAddOptionGroup(ids, groups) {
      this.groupIdsSelect = ids;
      this.groupsSelect = groups;
      this.item.modifier_option_group_ids = ids;
    },

    renameVariation(index) {
      var name = $("#variation_name_" + index).val();
      this.item.variations.name = name;
      if (name) {
        // $("#variation_name_error").addClass("d-none");
        

      } else {
        // $("#variation_name_error").removeClass("d-none");
      }
    },

    addVariation() {
      var name = $("#variation_name").val();
      if (name) {
        $("#variation_name").val("");
        $("#variation_name_error").addClass("d-none");
        var prices = [];
        this.priceList.forEach((price) => {
          prices.push({
            id: Math.random,
            price: 0,
            price_key: price.price_key,
            name: price.name,
            description: price.description,
            status: price.status,
          });
        });

        this.item.variations.push({
          variation_no: (Math.random() + 1).toString(36).substring(7),
          name: name,
          description: "",
          image: "",
          prices: prices,
          status: 1,
        });

        this.fieldVariationRequired.push({
          isNameRequire: false,
          isImageRequire: false,
        });
      } else {
        $("#variation_name_error").removeClass("d-none");
      }
    },

    removeVariation() {
      $(".btn-close-modal").click();
      let index = this.variationIndexSelectDelete;
      if (this.item.variations.length > 0) {
        if (this.item.variations[index] && this.item.variations[index].id) {
          this.item.variationIdDelete.push(this.item.variations[index].id)
        }
        this.item.variations.splice(index, 1);
        this.fieldVariationRequired.splice(index, 1);
      }

      if (this.item.variations.length == 0) {
        var prices = [];
        this.priceList.forEach((price) => {
          prices.push({
            id: Math.random,
            price: 0,
            price_key: price.price_key,
            name: price.name,
            description: price.description,
            status: price.status,
          });
        });
        this.item.others_price = prices;
      }
    },

    preventUpDown(event) {
      // Check if the up or down arrow key was pressed
      if (event.keyCode === 38 || event.keyCode === 40) {
        // Prevent the default behavior of the key event
        event.preventDefault();
      }
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
  },
};
</script>
<style scoped>
.modal-body {
  max-height: 85vh;
  overflow-y: auto;
  padding: 35px;
  overflow-x: hidden;
}
.modal-body .row {
  margin-top: 10px;
}
.content {
  cursor: pointer;
  text-align: center;
  width: 250px;
  height: 250px;
  margin-top: 25px;
  border: 1px solid #ccc;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #f0f2f5;
}
.font-18 {
  font-size: 18px !important;
}
.font-45 {
  font-size: 45px !important;
}
.dowload-img {
  text-align: center;
  cursor: pointer;
  background-color: #f0f2f5;
  width: 100%;
  height: 240px;
  overflow-y: auto;
}
.variation-option-dowload-img {
  width: 70px;
  height: unset !important;
}
.variation-option-dowload-img .css-icon-upload {
  margin-top: 0;
}
.variation-option-dowload-img .image-url {
  width: 70px;
  height: 70px;
}
.variation-option-dowload-img .btn-edit-img {
  top: 0px;
  right: 0px;
}
.css-icon-upload {
  font-size: 45px !important;
  margin-top: 4rem;
}
.image-url {
  width: 100%;
  height: 240px;
  object-fit: cover;
}
.col-mg {
  margin-bottom: 20px;
}
.modal-width {
  min-width: 60%;
}
.float-right {
  float: right;
}
.float-left {
  float: left;
}
.icon-arrow {
  position: absolute;
  transform: translateY(-11px);
  font-size: 45px;
}
.cate-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d5d6d8;
  padding: 15px 0;
  border-radius: 5px;
}
.cate-card {
  display: flex;
  align-items: center;
  border: 1px solid #ffffff;
  padding: 5px 10px;
  border-radius: 5px;
  margin-left: 10px;
}
.item-code {
  background-color: #d2d2d2;
  margin-left: 15px;
  margin-top: 4px;
}
.items-table-code {
  background-color: #d2d2d2;
  line-height: unset;
  transform: translateY(-3px);
}
.itme-product {
  color: #d2d2d2;
  margin-left: 15px;
}
.table-product {
  overflow-x: auto !important;
}
.modal-footer {
  display: unset;
}
.remove-price {
  margin-top: 2rem;
}
.tag-a-custom {
  color: #007bff !important;
  text-decoration: underline !important;
  cursor: pointer;
}
.btn-edit-img {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  color: #f44335;
  font-size: 14px;
  right: 30px;
  top: 4px;
  background: #ffffff;
  border-radius: 50%;
}
.cursor-pointer {
  cursor: pointer;
}
.variations-box,
.price-box,
.modifier-box,
.schedule-box {
  border: 1px solid #d5d6d8;
  border-radius: 5px;
  padding: 15px 25px;
  margin: 30px 0;
}

.variation-info {
  padding-top: 20px;
  padding-bottom: 20px;
}

.variation-info .btn {
  min-width: 75px !important;
}

.variation-info:not(:last-child) {
  border-bottom: 1px solid #d2d6da;
}
.schedule-list {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden !important;
}
.table-variation-price {
  min-width: 1200px;
  overflow-x: auto;
}
.schedule-list::-webkit-scrollbar {
  width: 7px;
}
.table-variation::-webkit-scrollbar {
  height: 7px;
}
.schedule-list::-webkit-scrollbar-track,
.table-variation::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.schedule-list::-webkit-scrollbar-thumb,
.table-variation::-webkit-scrollbar-thumb {
  background: #888;
}
.schedule-list::-webkit-scrollbar-thumb:hover,
.table-variation::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.fs-16 {
  font-size: 16px !important;
}
.table-responsive thead tr th {
  padding: 0.5rem !important;
}
.mw-300px {
  max-width: 300px !important;
}
.variation-price small {
  font-weight: normal !important;
  font-size: 13px;
}
</style>
