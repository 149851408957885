<template>
  <div>
    <div class="row mb-2 pe-4">
      <div class="col-lg-8 col-md-7 col-7 d-flex align-items-center">
        <h4
          class="col-lg-10 col-md-10 col-12 text-back d-flex align-items-center ps-2"
        >
          <i
            class="fa fa-chevron-circle-left pe-2"
            aria-hidden="true"
            @click="backVoucherList()"
            style="cursor: pointer"
          ></i>
          <div @click="backVoucherList()" style="cursor: pointer">BACK</div>
        </h4>
      </div>
    </div>
    <div class="page-header align-items-start min-vh-100 my-transacions">
      <!-- <span class="mask bg-white"></span> -->
      <div class="container"  v-if="isLoadDoneData">
        <div class="row card-custom-new pt-3">
          <div
            class="col-lg-12 col-md-12 col-12 mx-auto pb-5"
          >
            <h3 class="title-page ps-2">
              {{ voucher.id ? "EDIT VOUCHER BUNDLE" : "CREATE VOUCHER BUNDLE" }}
            </h3>
            <div class="table-responsive custom-table-noborder">
              <table class="table align-items-center mb-0">
                <tbody>
                  <tr>
                    <td class="align-middle text-left text-sm" colspan="10">
                      <div class="ps-0 text-title">
                        {{ voucher.id ? "Created on " : "Create on "
                        }}{{
                          voucherCreateAt + " by " + voucherCreateBy + " admin"
                        }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle text-left text-sm" colspan="2">
                      <div class="d-flex">
                        <div class="ps-0 label-form">Bundle ID</div>
                      </div>
                    </td>
                    <td colspan="8">
                      BUNDLE{{ voucher.id ?? voucherIdDummy }}
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle text-left text-sm" colspan="2">
                      <div class="d-flex">
                        <div class="ps-0 label-form">Bundle Title</div>
                        <span class="text-danger ms-2">*</span>
                      </div>
                    </td>
                    <td colspan="8">
                      <material-input
                        id="title"
                        v-model="voucher.title"
                        name="title"
                        maxLength="100"
                        placeholder="Enter voucher bundle title"
                      />
                      <span
                        class="is-invalid"
                        v-if="fieldRequired.isVoucherBundleTitleRequire"
                        >The field is required.</span
                      >
                    </td>
                  </tr>
                  <tr v-if="rewardPerTransactionType">
                    <td class="align-middle text-left text-sm" colspan="2">
                      <div class="d-flex row">
                        <div class="label-form col-9">Exchange Cashback</div>
                        <div
                          class="col-3 d-flex justify-content-end pe-1"
                          style="margin-top: 1px"
                        >
                          $
                        </div>
                      </div>
                    </td>
                    <td colspan="8">
                      <material-input
                        id="amount_convert"
                        type="number"
                        v-model="voucher.amount_convert"
                        name="amount_convert"
                        placeholder="Enter exchange Amount"
                      />
                    </td>
                  </tr>
                  <tr v-if="rewardPerTransactionType">
                    <td class="align-middle text-left text-sm" colspan="2">
                      <div class="d-flex row">
                        <div class="label-form col-9">Exchange Point</div>
                      </div>
                    </td>
                    <td colspan="8">
                      <material-input
                        id="points_convert"
                        type="number"
                        v-model="voucher.points_convert"
                        name="points_convert"
                        placeholder="Enter exchange Point"
                      />
                    </td>
                  </tr>
                  <tr v-for="(item, index) in voucher.vouchers" :key="index">
                    <td class="align-middle text-left text-sm" colspan="2">
                      <div class="d-flex row">
                        <div class="label-form col-9" v-if="index == 0">Vouchers</div>
                      </div>
                    </td>
                    <td colspan="5">
                      <Multiselect v-model="item.value"  :options="dataVoucher" :canClear="false" />
                    </td>
                    <td colspan="1">
                      <a href="javascript:;" style="text-decoration: underline;" @click="removeMoreWelcomeVoucher(index, indexWelcomeVoucher)">Remove</a>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2"></td>
                    <td colspan="3">
                      <a href="javascript:;" style="text-decoration: underline;" @click="addMoreWelcomeVoucher()">Add more voucher</a>
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle text-left text-sm" colspan="2">
                      <div class="d-flex row">
                        <div class="label-form col-9">Total</div>
                      </div>
                    </td>
                    <td colspan="8">
                      <material-input
                        id="total"
                        type="number"
                        v-model="voucher.total"
                        name="total"
                        placeholder="Enter exchange Point"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle" colspan="2">
                      <div class="ps-0 label-form">Number of Redemption</div>
                    </td>
                    <td colspan="1">
                      <MaterialRadio
                        v-model="voucher.usability_type"
                        id="one_time_per_member"
                        value="1"
                      >
                        One time per member
                      </MaterialRadio>
                      <MaterialRadio
                        v-model="voucher.usability_type"
                        id="mutiple_times_per_member"
                        value="2"
                      >
                        Mutiple times per member
                      </MaterialRadio>
                    </td>
                    <td colspan="1">
                      <div style="margin-top: 1.7rem;">
                        <material-input
                          type="number"
                          v-model="voucher.usability_number"
                          id="usability_number"
                          name="usability_number"
                          class="align-items-end"
                          placeholder="Enter usability number"
                        />
                      </div>
                    </td>
                    <td colspan="1">
                      <div style="margin-top: 1.7rem;">Times for every</div>
                    </td>
                    <td colspan="3">
                      <Multiselect
                        v-model="voucher.usability_unit"
                        :options="usabilityUnit"
                        placeholder="Select Time Use After"
                        style="margin-top: 1.7rem;"
                        :canClear="false"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle" colspan="2">
                      <div class="ps-0 label-form d-flex">
                        Expiry Date <span class="text-danger ms-2">*</span>
                      </div>
                    </td>
                    <td colspan="1">
                      <material-checkbox
                        v-model="voucher.fixed_date"
                        :checked="parseInt(voucher.fixed_date) ? true : false"
                        name="fixed_date"
                        id="fixed_date"
                        class="align-items-center px-3"
                      >
                        <template v-slot:label
                          >Fixed Date</template
                        ></material-checkbox
                      >
                    </td>
                    <td colspan="5">
                      <Datepicker
                        v-model="voucher.expiry_date"
                        :enableTimePicker="false"
                        class="date-time"
                        :format="format"
                      />
                    </td>
                  </tr>
                  <tr v-if="fieldRequired.isFixedDateRequire">
                    <td class="align-middle" colspan="2"></td>
                    <td colspan="1">
                    </td>
                    <td colspan="7" class="align-items-end pt-0">
                      <span class="is-invalid">{{ textRequiredDateCustom }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle" colspan="2"></td>
                    <td colspan="1" class="d-flex">
                      <material-checkbox
                        v-model="voucher.expired_after"
                        :checked="
                          parseInt(voucher.expired_after) ? true : false
                        "
                        name="expired_after"
                        id="expired_after"
                        class="checkbox-custom px-3 pt-2"
                      >
                        <template v-slot:label
                          >Expired after</template
                        ></material-checkbox
                      >
                      <material-input
                        class="d-flex align-items-center"
                        id="time_number"
                        type="number"
                        v-model="voucher.time_number"
                        name="time_number"
                        placeholder="Enter time number"
                        style="width: 150px"
                      />
                    </td>
                    <td colspan="2">
                      <Multiselect
                        v-model="voucher.time_unit"
                        :options="timeUnits"
                        placeholder="Select Time Unit"
                        :canClear="false"
                      />
                    </td>
                    <td>of issue</td>
                  </tr>
                  <tr v-if="fieldRequired.isDateRequire || fieldRequired.isTimeNumberRequire">
                    <td class="align-middle" colspan="2"></td>
                    <td colspan="1">
                      <span class="is-invalid" v-if="fieldRequired.isTimeNumberRequire">{{ textExpiredAfterCustom }}</span>
                    </td>
                    <td colspan="7" class="align-items-end pt-0">
                      <span class="is-invalid" v-if="fieldRequired.isDateRequire">Expiry date is required.</span>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="10" style="width: 100%">
                      <div class="ps-0 label-form">Terms & Conditions</div>
                      <material-textarea v-model="voucher.terms_conditions" />
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle" colspan="2" style="width: 20%">
                      <div class="d-flex">
                        <div class="ps-0 label-form">Bundle Image</div>
                      </div>
                    </td>
                    <td colspan="2">
                      <div
                        class="d-flex justify-content-left align-items-center"
                      >
                        <div class="align-items-center">
                          <material-input
                            style="display: none"
                            type="file"
                            id="image"
                            @change="handleFileUpload($event)"
                            accept="image/*"
                          />
                          <material-button
                            @click="uploadFile()"
                            :disabled="false"
                            class="btn-background btn btn-sm capitalize"
                          >
                            Upload Image
                          </material-button>
                          <div
                            class="is-invalid d-flex align-items-center pe-2"
                            v-if="fieldRequired.isImageRequire"
                          >
                            {{ messageImageError }}
                          </div>
                          <div class="text-size-13">
                            Uploaded image minimum 100 x 100.
                          </div>
                        </div>
                        <div
                          id="preview"
                          class="ps-2"
                          style="position: relative"
                        >
                          <img
                            v-if="urlImagePreview || voucher.icon"
                            :src="
                              urlImagePreview ? urlImagePreview : voucher.icon
                            "
                          />
                          <i
                            class="fa fa-times icon-close-sidenav-main danger"
                            v-if="urlImagePreview || voucher.icon"
                            style="
                              position: absolute;
                              top: -8px;
                              left: 130px;
                              color: #f44335;
                              font-size: 20px;
                            "
                            aria-hidden="true"
                            @click="clearImage()"
                          ></i>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle" colspan="2">
                      <div class="ps-0 label-form">Bundle Status</div>
                    </td>
                    <td colspan="8" class="d-flex">
                      <MaterialRadio
                        class="ps-0"
                        v-model="voucher.status"
                        id="active"
                        value="1"
                      >
                        Active
                      </MaterialRadio>
                      <MaterialRadio
                        v-model="voucher.status"
                        id="deactive"
                        value="0"
                      >
                        Inactive
                      </MaterialRadio>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table class="table align-items-center mb-0 mt-3">
                <tbody>
                  <tr>
                    <td colspan="10">
                      <div class="d-flex justify-content-center pt-3">
                        <material-button
                          @click="saveVoucher(index)"
                          :disabled="disableBtnSubmit"
                          class="mx-2 btn-background"
                          :style="`width: 300px; background: ${this.$store.state.buttonColor} !important`"
                        >
                          Save
                        </material-button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="position-fixed top-1 end-1 z-index-2 message-success">
        <material-snackbar
          v-if="snackbar === 'danger' || snackbar === 'success'"
          title=""
          date=""
          :description="message"
          :icon="{ component: 'campaign', color: 'white' }"
          :color="snackbar"
          :close-handler="closeSnackbar"
        />
      </div>
      <material-loading :active="loaderActive" />
    </div>
  </div>
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import "bootstrap/dist/js/bootstrap.bundle";
import MaterialLoading from "@/components/MaterialLoading.vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import "bootstrap/js/dist/modal";
import MaterialButton from "@/components/MaterialButton.vue";
import SettingVoucherService from "../../../services/settingVoucher.service";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialRadio from "@/components/MaterialRadio.vue";
import MaterialTextarea from "@/components/MaterialTextarea.vue";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import CommonFunctionService from "../../../services/commonFunction.service";
import moment from "moment";
import $ from "jquery";

export default {
  name: "Voucher",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    MaterialButton,
    MaterialInput,
    MaterialRadio,
    Multiselect,
    Datepicker,
    MaterialTextarea,
    MaterialCheckbox,
  },
  data() {
    return {
      message: "",
      snackbar: null,
      loaderActive: false,
      fieldRequired: {},
      indexItem: 0,
      textRequiredDateCustom: "",
      textRequiredRewardUnitCustom: "",
      textExpiredAfterCustom: "",
      urlImagePreview: null,
      messageImageError: "",
      isErrorUploadImage: false,
      isImageTooSmall: false,
      voucher: {
        id: null,
        title: "",
        amount_convert: 0,
        points_convert: 0,
        total: 0,
        vouchers: [],
        usability_type: 1,
        usability_number: null,
        usability_unit: 1,
        expiry_date: null,
        fixed_date: 0,
        expired_after: 0,
        time_unit: 1,
        terms_conditions: "",
        icon: null,
        status: 0
      },
      voucherIdDummy: "",
      isLoadDoneData: false,
      merchantCode: "",
      voucherCreateAt: "",
      voucherCreateBy: "",
      time_number: null,
      timeUnits: [],
      rewardPerTransactionType: 0,
      dataVoucher: [],
      usabilityUnit: [],
    };
  },
  created() {
    this.getVoucherDetail();
  },
  mounted() {
    const code = localStorage.getItem("MERCHANT_CODE", "");
    this.merchantCode = code ? `/${code}` : "";
  },
  methods: {
    format(date) {
      return CommonFunctionService.formatDateShow(date);
    },

    handleFileUpload(event) {
      this.isImageTooSmall = false;
      let file = event.target.files[0];
      if (file != null || file != undefined) {
        this.voucher.icon = null;
        this.urlImagePreview = null;
        if (
          !["image/png", "image/jpg", "image/jpeg", "image/svg"].includes(
            file.type
          )
        ) {
          this.fieldRequired.isImageRequire = true;
          this.isErrorUploadImage = false;
          this.messageImageError = `File must be an image.`;
        } else {
          this.voucher.icon = file;
          this.urlImagePreview = URL.createObjectURL(this.voucher.icon);
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (evt) => {
            let img = new Image();
            img.onload = () => {
              let width = img.width;
              let height = img.height;
              if (width < 100 || height < 100) {
                this.messageImageError = `The image width ${width} x ${height} is too small.`;
                this.fieldRequired.isImageRequire = true;
                this.isErrorUploadImage = true;
                this.isImageTooSmall = true;
              } else {
                this.fieldRequired.isImageRequire = false;
                this.isErrorUploadImage = false;
                this.isImageTooSmall = false;
              }
            };
            img.src = evt.target.result;
          };
        }
      } else {
        this.voucher.icon = null;
        this.urlImagePreview = null;
        this.fieldRequired.isImageRequire = true;
        this.isErrorUploadImage = false;
        this.messageImageError = `File must be an image.`;
      }
      $("#image")[0].value = '';
    },

    clearImage() {
      this.voucher.icon = null;
      this.urlImagePreview = null;
      this.fieldRequired.isImageRequire = false;
      this.isErrorUploadImage = false;
      this.isImageTooSmall = false;
    },

    dateFormat(expiryDate) {
      if (expiryDate == null) {
        return null;
      } else {
        return moment(expiryDate).format("YYYY-MM-DD") + " " + "00:00:00";
      }
    },
    closeSnackbar() {
      this.snackbar = null;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },

    getVoucherDetail() {
      var voucherId = this.$route.params.id;
      this.isLoadDoneData = false;
      this.showLoader();
      if (voucherId) {
        SettingVoucherService.getDataVoucherBundleDetail(voucherId).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.voucherCreateAt = response.data.data.create_at;
              this.voucherCreateBy = response.data.data.create_by;
              this.voucher = response.data.data.voucher_bundle;
              this.timeUnits = response.data.data.time_unit;
              this.rewardPerTransactionType = response.data.data.reward_per_transaction_type;
              this.dataVoucher = response.data.data.data_voucher;
              this.usabilityUnit = response.data.data.usabilityUnit;
              this.isLoadDoneData = true;
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      } else {
        SettingVoucherService.createNewVoucherBundle().then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.voucherCreateAt = response.data.data.create_at;
              this.voucherCreateBy = response.data.data.create_by;
              this.voucherIdDummy = response.data.data.voucher_bundle_id;
              this.timeUnits = response.data.data.time_unit;
              this.rewardPerTransactionType = response.data.data.reward_per_transaction_type;
              this.dataVoucher = response.data.data.data_voucher;
              this.usabilityUnit = response.data.data.usabilityUnit;
              this.isLoadDoneData = true;
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      }
    },

    saveVoucher() {
      var page = this.$route.query.page ?? 1;
      if (this.isImageTooSmall) {
        this.isErrorUploadImage = true;
        this.fieldRequired.isImageRequire = true;
      } else {
        this.fieldRequired.isImageRequire = false;
      }
      this.voucher.expiry_date = this.dateFormat(this.voucher.expiry_date);
      this.showLoader();
      if (this.processValidate() && !this.isErrorUploadImage) {
        this.voucher.fixed_date = this.voucher.fixed_date ? 1 : 0;
        this.voucher.expired_after = this.voucher.expired_after ? 1 : 0;
        SettingVoucherService.addOrUpdateVoucherBundle(this.voucher).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.snackbar = "success";
              this.message = "Save Voucher Success";
              this.voucher.id = response.data.data.merchant_reward
                ? response.data.data.merchant_reward.id
                : null;
              this.setTimeOutNotification();
              this.$router.push("/vouchers-bundle/?page=" + page);
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      } else {
        this.hideLoader();
      }
    },

    resetRequired() {
      this.fieldRequired.isVoucherBundleTitleRequire = false;
      this.fieldRequired.isDateRequire = false;
      this.fieldRequired.isTimeNumberRequire = false;
      this.fieldRequired.isFixedDateRequire = false;
    },

    processValidate() {
      this.resetRequired();
      var isValid = true;
      if (
        this.voucher.title == "" ||
        this.voucher.title == null
      ) {
        this.fieldRequired.isVoucherBundleTitleRequire = true;
        isValid = false;
      }

      const expiryDate = this.dateFormat(this.voucher.expiry_date);
      if (!this.voucher.expired_after) {
        this.fieldRequired.isDateRequire = true;
        isValid = false;
      }
      if (this.voucher.expired_after && !this.voucher.time_number) {
        this.fieldRequired.isTimeNumberRequire = true;
        this.textExpiredAfterCustom = "Time number is required";
        isValid = false;
      }
      if (!this.voucher.expired_after && this.voucher.time_number) {
        this.fieldRequired.isTimeNumberRequire = true;
        this.textExpiredAfterCustom = "Expired after is required";
        isValid = false;
      }

      if (
        (!this.voucher.fixed_date && expiryDate != null) ||
        (this.voucher.fixed_date && expiryDate == null)
      ) {
        this.fieldRequired.isFixedDateRequire = true;
        this.textRequiredDateCustom = "Fixed date is required";
        isValid = false;
      }

      return isValid;
    },

    backVoucherList() {
      var page = this.$route.query.page;
      if (page) {
        this.$router.push("/vouchers-bundle" + "?page=" + page);
      } else {
        this.$router.push("/vouchers-bundle");
      }
    },

    addMoreWelcomeVoucher() {
      const vouchers = this.voucher.vouchers;
      vouchers.push({ id: null, value: 0 });
    },
  
    removeMoreWelcomeVoucher(index) {
      const vouchers = this.voucher.vouchers;
      vouchers.splice(index, 1);
    },

    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
    uploadFile() {
      $("#image").click();
    },
  },
};
</script>
<style scoped>
.text-back {
  color: black !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  font-family: "Roboto Slab", sans-serif !important;
}

#preview img {
  max-width: 150px;
  max-height: 60px;
}

.voucher-report {
  background-color: #eeeeee;
  color: #82001e;
}
.link-overview {
  color: #203ace;
  text-decoration: underline;
}
.link-overview:hover {
  cursor: pointer;
}
</style>
