import api from './api';

class DashboardReportService {
  getData(dataForm) {
    return api.post('/merchant/report/voucher-redeem', dataForm);
  }

  getDataSlidesShow(page, filter_time = "", from_date, to_date, sortCol = null, sortType = null) {
    return api.get('/merchant/report/slides-show?page='+page+"&time_type="+filter_time+"&from_date="+from_date+"&to_date="+to_date+"&sortCol="+sortCol+"&sortType="+sortType);
  }

  getFilterDataTopupReport() {
    return api.get('/merchant/report/top-up/get-filter-data');
  }

  getDataTopupReport(dataForm) {
    return api.post('/merchant/report/top-up', dataForm);
  }

  getDataVoidTopupReport(dataForm) {
    return api.post('/merchant/report/void-top-up', dataForm);
  }

  getDataPosTransaction(dataForm) {
    return api.post('/merchant/report/pos-transaction', dataForm);
  }

  getDataPosOrderItemReport(dataForm) {
    return api.post('/merchant/report/pos-order-item-report', dataForm);
  }

  getDataOnlineOrderingReport(dataForm) {
    return api.post('/merchant/report/online-ordering', dataForm);
  }

  getDataWalletReport(dataForm) {
    return api.post('/merchant/report/pos-wallet-report', dataForm);
  }

  getDataPaidMembershipReport(dataForm) {
    return api.post('/merchant/report/paid-membership', dataForm);
  }

}

export default new DashboardReportService();
