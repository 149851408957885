<template>
  <div>
    <div class="row mb-2 pe-4">
      <div class="col-lg-8 col-md-7 col-7 d-flex align-items-center">
        <div class="color-common d-flex align-items-center">
          <div class="btn-back d-flex align-items-center ps-0 mt-2" v-if="menuSet.id">
            <h4 @click="backMenusetList()" class="cursor-pointer">
              <i
                class="fa fa-chevron-circle-left pe-2"
                aria-hidden="true"
              ></i>
              <span>BACK</span>
            </h4>
          </div>
          <h3 class="title-page mb-0 ps-3">{{ menuSet.id ? 'Menu details' : 'Create Menu' }}</h3>
        </div>
      </div>
    </div>
  </div>
  <div class="page-header align-items-start min-vh-100">
    <span class="mask bg-white"></span>
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-12 mx-auto">
          <div class="row mt-5">
            <div class="d-flex p-0">
              <div class="w-35">
                <h5>Menu Image</h5>
                <div
                  class="dowload-img"
                  v-show="urlImagePreview || menuSet.image"
                >
                  <div id="preview" style="position: relative">
                    <img
                      style="width: 100%; height: 350px"
                      v-if="urlImagePreview || menuSet.image"
                      :src="urlImagePreview || menuSet.image"
                    />
                    <i
                      class="fa fa-times icon-close-sidenav-main danger"
                      v-if="urlImagePreview || menuSet.image"
                      style="
                        position: absolute;
                        color: #f44335;
                        font-size: 24px;
                        right: 5px;
                        top: 6px;
                      "
                      aria-hidden="true"
                      @click="clearImage()"
                    ></i>
                    <div class="btn-edit-img">
                      <i
                        class="fas fa-pencil-alt text-dark"
                        v-if="urlImagePreview || menuSet.image"
                        aria-hidden="true"
                        @click="uploadFile()"
                      ></i>
                    </div>
                  </div>
                </div>
                <div
                  class="dowload-img"
                  @click="uploadFile()"
                  v-show="!urlImagePreview && !menuSet.image"
                >
                  <material-input
                    style="display: none"
                    type="file"
                    id="image"
                    @change="handleFileUpload($event)"
                    accept="image/*"
                  />
                  <i class="material-icons css-icon-upload">backup</i>
                  <h6>Click here to upload an image.</h6>
                </div>
                <div
                  class="is-invalid d-flex align-items-center pe-2"
                  v-if="fieldRequired.isImageRequire"
                >
                  {{ messageImageError }}
                </div>
              </div>
              <div class="w-65 mb-5">
                <div class="w-100">
                  <h5>Menu Name</h5>
                  <material-input
                    v-model="menuSet.name"
                    type="text"
                    id="menuset_name"
                    name="menuset_name"
                    placeholder="Enter name"
                  />
                </div>
                <div class="w-100">
                  <span
                    class="is-invalid"
                    v-if="fieldRequired.isMenuNameRequire"
                    >The field is required.</span
                  >
                </div>
                <div class="w-100 mt-4">
                  <h5>Menu Description</h5>
                  <material-textarea
                    rows="12"
                    v-model="menuSet.description"
                    id="menuset_description"
                    name="menuset_description"
                    placeholder="Enter description"
                  />
                </div>
                <div class="w-100 mt-4" v-if="menuSetIdSelect">
                  <h5>Schedule</h5>
                  <div class="schedule-list">
                    <div v-for="(schedule, index) in scheduleList" :key="index">
                      <div class="mt-2">
                        <div>
                          {{ rangeTime(schedule) }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <material-button
                    @click="addScheduleMenuSet"
                    class="btn btn-sm btn-background mt-4"
                    >Add Schedule</material-button
                  >
                </div>
                <div class="w-100 mt-4">
                  <h5>Price List</h5>
                  <div class="price-list">
                    <div class="table-responsive p-0 mt-3">
                      <table class="table align-items-center mb-0">
                        <thead>
                          <tr>
                            <th>Display</th>
                            <th class="w-30">Label</th>
                            <th class="w-35">Description</th>
                            <th>Default Price</th>
                            <th>Member Price</th>
                            <th>Category Selected</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(price, index) in menuSet.priceList"
                            :key="index"
                          >
                            <td>
                              <material-checkbox
                                v-model="price.is_display"
                                :checked="price.is_display"
                                class="align-items-center checkbox-custom"
                                :isDisabled="price.is_price_default || price.is_member_price || price.price_key.includes(menuSet.priceKeyCategorySelect)"
                              ></material-checkbox>
                            </td>
                            <td>{{ price.name }}</td>
                            <td>
                              <material-input
                                v-model="price.description"
                                type="text"
                                :id="'price_description_' + index"
                                :name="'price_description_' + index"
                              />
                            </td>
                            <td>
                              <MaterialRadio
                                v-model="price.is_price_default"
                                name="price_default"
                                value="1"
                                @change="changePriceDefault(index)"
                              >
                              </MaterialRadio>
                            </td>
                            <td>
                              <MaterialRadio
                                v-model="price.is_member_price"
                                name="member_price"
                                value="1"
                                @change="changeMemberPrice(index)"
                              >
                              </MaterialRadio>
                            </td>
                            <td class="text-center">
                              <material-checkbox
                                :checked="price.price_key.includes(menuSet.priceKeyCategorySelect)"
                                class="align-items-center checkbox-custom"
                                :isDisabled="true"
                              ></material-checkbox>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end mb-5">
            <material-button
              class="mx-2 btn btn-sm btn-background ms-0"
              @click.once="saveMenu()"
              >Save</material-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="position-fixed top-1 end-1 z-index-2 message-success">
    <material-snackbar
      v-if="snackbar === 'danger' || snackbar === 'success'"
      title
      date
      :description="message"
      :icon="{ component: 'campaign', color: 'white' }"
      :color="snackbar"
      :close-handler="closeSnackbar"
    />
  </div>
  <material-loading :active="loaderActive" />
  <ModalMenuSetSchedule
    :keyReload="keyReloadScheduleModal"
    @reload-schedule="reloadSchedule"
    :branchIdSelectEdit="branchIdSelectEdit"
    :categoryIdSelect="categoryIdSelect"
    :itemIdSelect="itemIdSelect"
  ></ModalMenuSetSchedule>
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import "bootstrap/dist/js/bootstrap.bundle";
import MaterialLoading from "@/components/MaterialLoading.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialTextarea from "@/components/MaterialTextarea.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import $ from "jquery";
// import ModalAddItem from "@/components/ModalAddItem.vue";
import MenusetService from "../../services/product/menuset.service";
// import TestService from "../../services/test.service";
import ModalMenuSetSchedule from "@/components/ModalMenuSetSchedule.vue";
// import Multiselect from "@vueform/multiselect";
import MaterialRadio from "@/components/MaterialRadio.vue";
import ScheduleService from "../../services/product/schedule.service";

export default {
  name: "create-menu",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    MaterialInput,
    MaterialTextarea,
    MaterialButton,
    MaterialCheckbox,
    ModalMenuSetSchedule,
    MaterialRadio,
  },
  data() {
    return {
      snackbar: null,
      message: "",
      urlImagePreview: null,
      isErrorUploadImage: false,
      isImageTooSmall: false,
      messageImageError: "",
      fieldRequired: {},
      loaderActive: false,
      menuSet: {
        id: this.$route.params.id ?? 0,
        name: "",
        description: "",
        image: null,
        priceList: [],
        priceKeyCategorySelect: []
      },
      menuSetIdSelect: this.$route.params.id,
      keyReloadScheduleModal: "key-reload-schedule-modal",
      scheduleList: [],
      priceListDisplay: [],
    };
  },
  async mounted() {
    if (this.menuSet.id) {
      await this.getMenuDetail();
      this.getScheduleInfo();
    }
    this.getPriceList();
  },
  methods: {
    backMenusetList() {
      this.$router.push('/menu-set?set_id=' + this.menuSet.id);
    },

    changePriceDefault(index) {
      this.menuSet.priceList.forEach((price) => {
        price.is_price_default = 0;
      })
      this.menuSet.priceList[index].is_price_default = 1;
      this.menuSet.priceList[index].is_display = 1;
    },

    changeMemberPrice(index) {
      this.menuSet.priceList.forEach((price) => {
        price.is_member_price = 0;
      })
      this.menuSet.priceList[index].is_member_price = 1;
      this.menuSet.priceList[index].is_display = 1;
    },
  
    uploadFile() {
      $("#image").click();
    },

    clearImage() {
      this.menuSet.image = null;
      this.urlImagePreview = null;
      this.fieldRequired.isImageRequire = false;
      this.isErrorUploadImage = false;
      this.isImageTooSmall = false;
    },

    handleFileUpload(event) {
      this.isImageTooSmall = false;
      let file = event.target.files[0];
      if (file != null || file != undefined) {
        this.menuSet.image = null;
        this.urlImagePreview = null;
        if (
          !["image/png", "image/jpg", "image/jpeg", "image/svg"].includes(
            file.type
          )
        ) {
          this.isErrorUploadImage = false;
          this.fieldRequired.isImageRequire = true;
          this.messageImageError = `File must be an image.`;
        } else {
          this.menuSet.image = file;
          this.urlImagePreview = URL.createObjectURL(file);
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (evt) => {
            let img = new Image();
            img.onload = () => {
              let width = img.width;
              let height = img.height;
              if (width < 100 || height < 100) {
                this.messageImageError = `The image width ${width} x ${height} is too small.`;
                this.fieldRequired.isImageRequire = true;
                this.isErrorUploadImage = true;
                this.isImageTooSmall = true;
              } else {
                this.fieldRequired.isImageRequire = false;
                this.isErrorUploadImage = false;
                this.isImageTooSmall = false;
                this.messageImageError = "";
              }
            };
            img.src = evt.target.result;
          };
        }
      } else {
        this.menuSet.image = null;
        this.urlImagePreview = null;
        this.isErrorUploadImage = false;
        this.messageImageError = "";
      }
    },

    async getMenuDetail() {
      var menuId = this.$route.params.id;
      if (menuId) {
        this.showLoader();
        await MenusetService.menusetDetail(menuId).then(
          (response) => {
            if (response.data.result.isSuccess) {
              this.menuSet = response.data.data;
            } else {
              this.snackbar = "danger";
              this.message = response.data.result.message;
              this.setTimeOutNotification();
            }
            this.hideLoader();
          },
          (error) => {
            this.snackbar = "danger";
            this.message = error;
            this.hideLoader();
            this.setTimeOutNotification();
          }
        );
      }
    },

    getPriceList(setId = 0) {
      this.showLoader();
      var dataForm = {
        set_id: setId ? setId : this.$route.params.id,
      };

      MenusetService.getPriceList(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.menuSet.priceList = response.data.data.price_list;
            this.priceListDisplay = response.data.data.price_list_display;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    saveMenu() {
      if (this.processValidate()) {
        this.showLoader();
        var menuId = this.$route.params.id ?? 0;
        if (menuId) {
          this.menuSet.id = menuId;
          MenusetService.update(this.menuSet).then(
            (response) => {
              if (response.data.result.isSuccess) {
                this.snackbar = "success";
                this.message = "Update MenuSet Success";
                this.getScheduleInfo();
                this.getPriceList();
                this.setTimeOutNotification();
                setTimeout(() => {
                  this.$router.push("/menu-set?set_id=" + menuId);
                }, 1000);
              } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
              }
              this.hideLoader();
            },
            (error) => {
              this.snackbar = "danger";
              this.message = error.response.data.result.message;
              this.hideLoader();
              this.setTimeOutNotification();
            }
          );
        } else {
          MenusetService.store(this.menuSet).then(
            (response) => {
              if (response.data.result.isSuccess) {
                let menuSet = response.data.data;
                this.snackbar = "success";
                this.message = "Save MenuSet Success";
                this.setTimeOutNotification();
                this.menuSetIdSelect = menuSet.id;
                this.getPriceList(menuSet.id);
                setTimeout(() => {
                  this.$router.push("/menu-set");
                }, 1000);
              } else {
                this.snackbar = "danger";
                this.message = response.data.result.message;
                this.setTimeOutNotification();
              }
              this.hideLoader();
            },
            (error) => {
              this.snackbar = "danger";
              this.message = error;
              this.hideLoader();
              this.setTimeOutNotification();
            }
          );
        }
      }
    },

    getScheduleInfo() {
      this.showLoader();
      var dataForm = {
        set_id: this.$route.params.id,
      };

      ScheduleService.getScheduleInfo(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.scheduleList = response.data.data;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    reloadSchedule(mess) {
      this.snackbar = "success";
      this.message = mess;
      this.setTimeOutNotification();
      this.getScheduleInfo();
    },

    rangeTime(schedule) {
      return (
        schedule.day +
        " " +
        this.formatDate(schedule.time_from) +
        " - " +
        this.formatDate(schedule.time_to)
      );
    },

    formatDate(date) {
      return new Date(date).toLocaleTimeString("fr-FR", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
    },

    resetRequired() {
      this.fieldRequired.isMenuNameRequire = false;
    },

    processValidate() {
      this.resetRequired();
      var isValid = true;
      if (this.menuSet.name == "" || this.menuSet.name == null) {
        this.fieldRequired.isMenuNameRequire = true;
        isValid = false;
      }

      return isValid;
    },

    addScheduleMenuSet() {
      this.keyReloadScheduleModal = Math.random();
      $("#show-modal-menuset-schedule").click();
    },

    showLoader() {
      this.loaderActive = true;
    },

    hideLoader() {
      this.loaderActive = false;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },
  },
};
</script>

<style scoped>
.page-header .container {
  color: #222 !important;
}
.dowload-img {
  text-align: center;
  cursor: pointer;
  background-color: #f0f2f5;
  width: 80%;
  height: 350px;
}
.add-category {
  text-align: center;
  cursor: pointer;
  background-color: #f0f2f5;
  width: 100%;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.w-35 {
  width: 35%;
}
.w-65 {
  width: 65%;
}
.css-icon-upload {
  font-size: 120px !important;
  margin-top: 4rem;
}

.css-icon {
  position: absolute;
  left: 17px !important;
  font-size: 18px !important;
  top: 7px !important;
}

.cate-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d5d6d8;
  background: #ebecee !important;
  padding: 15px 0;
  border-radius: 5px;
}

.category-box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;
  border: 1px solid #d5d6d8;
  color: #82001e;
  border-radius: 5px;
}

.category-box:last-child {
  margin-bottom: 50px;
}

.cate-card {
  display: flex;
  align-items: center;
  border: 1px solid #ffffff;
  background: #ffffff;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 5px;
}

.card-item.add-items {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 75px 0 !important;
}
.card-item {
  border: 1px solid #d5d6d8;
  color: #82001e;
  border-radius: 5px;
  padding: 7px;
  margin-top: 15px;
}

.card-item img {
  width: 175px !important;
  height: 175px !important;
}

.card-item .card-content {
  width: calc(100% - 200px) !important;
}

.material-icons-round {
  width: 30px;
}

.cl-22 {
  color: #222 !important;
}

.mw-180px {
  min-width: 180px;
}

.btn-edit-img {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  color: #f44335;
  font-size: 14px;
  right: 30px;
  top: 4px;
  background: #ffffff;
  border-radius: 50%;
}

.schedule-list {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden !important;
}

.schedule-list::-webkit-scrollbar {
  width: 7px;
}

.schedule-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.schedule-list::-webkit-scrollbar-thumb {
  background: #888;
}

.schedule-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.dropdown-menu {
  border: 1px solid #d5d6d8;
  min-width: 9rem;
}
.cursor-pointer {
  cursor: pointer !important;
}

.unset-ov {
  overflow: unset !important;
}

.table-responsive thead tr th {
  padding: 0.5rem !important;
}

.activeLayout {
  background: white;
  align-items: center !important;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
  padding: 6px;
  cursor: pointer;
}

.inActiveLayout {
  /* background: gray; */
  align-items: center !important;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
  padding: 6px;
  cursor: pointer;
}

.btn-back h4 {
  color: black !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  font-family: "Roboto Slab", sans-serif !important;
}
</style>
