import api from './api';

class SettingPointService {
  getDataPoint() {
    return api.get('/merchant/point-setting');
  }

  addOrUpdatePoint(dataForm) {
    return api.post('/merchant/point-setting', dataForm);
  }

  deletePoint(pointId) {
    return api.post('/merchant/point-setting/delete/' + pointId);
  }

  addPointIcon(dataForm) {
    return api.post('/merchant/point-setting/add-icon', dataForm, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
}

export default new SettingPointService();
