<template>
  <div>
    <div class="row mb-2 pe-4">
      <div class="col-lg-8 col-md-7 col-7 d-flex align-items-center">
        <div class="color-common">
          <h3 class="title-page mb-0 ps-3">VOUCHER BUNDLE LIST</h3>
        </div>
      </div>
      <div class="col-lg-4 col-md-5 col-5 d-flex justify-content-end">
        <material-button
          @click="createVoucherBundle()"
          :disabled="disableBtnSubmit"
          class="mx-2 btn btn-sm btn-background"
        >
          Add VOUCHER BUNDLE
        </material-button>
      </div>
    </div>
    <div class="page-header align-items-start min-vh-100 my-transacions">
      <span class="mask bg-white"></span>
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12 mx-auto" v-if="isLoadData">
            <div class="row my-3 d-flex align-items-left">
              <div class="col-lg-6 col-md-6 col-12">
                <input
                  class="nosubmit"
                  type="search"
                  id="keyword_search"
                  v-model="searchKeyword"
                  extraClass
                  name="keyword_search"
                  placeholder="Search by voucher bundle title"
                  @input="onChangesearchKeyword"
                />
              </div>
              <div class="col-lg-6 col-md-6 col-12 d-flex justify-content-end">
                <div class="dropdown">
                  <button class="btn btn-secondary dropdown-toggle btn-sm" type="button" id="dropdownMenuButton1" style="margin-top: 13px;"
                    data-bs-toggle="dropdown" aria-expanded="false" aria-haspopup="true">
                    {{ $t('download') }}
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li>
                      <a class="dropdown-item" @click="exportFile('csv')" href="#">Export CSV</a>
                    </li>
                    <li>
                      <a class="dropdown-item" @click="exportFile('xlsx')" href="#">Export Excel</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="row mt-1">
              <div class="col-12">
                <div class="card-custom">
                  <div class="px-0 pb-2">
                    <div class="table-responsive p-0 custom-table">
                      <table class="table align-items-center mb-0">
                        <thead>
                          <tr>
                            <th class="text-center font-weight-bolder w-5">{{ $t('ID') }}</th>
                            <th class="text-center font-weight-bolder">
                              {{ $t('Title') }}
                            </th>
                            <th class="text-center font-weight-bolder w-10">{{ $t('Image') }}</th>
                            <th class="text-center font-weight-bolder">{{ $t('Number_Of_Vouchers') }}</th>
                            <th class="text-center font-weight-bolder">
                              {{ $t('Exchange_Value') }}
                            </th>
                            <th class="text-center font-weight-bolder">
                              {{ $t('Total') }}
                            </th>
                            <th class="text-center font-weight-bolder">
                              {{ $t('Sold') }}
                            </th>
                            <th class="text-center font-weight-bolder">
                              {{ $t('Expiry_Date') }}
                            </th>
                            <th class="text-center font-weight-bolder">
                              {{ $t('status') }}
                            </th>
                            <th class="text-center font-weight-bolder">
                              {{ $t('action') }}
                            </th>
                          </tr>
                        </thead>
                        <tbody v-if="vouchers.length">
                          <tr v-for="(value, key) in vouchers" :key="key">
                            <td class="text-center text-xss w-5">
                              BUNDLE{{ value.id }}
                            </td>
                            <td class="text-center text-xss w-25" @click="editVoucher(value.id)">
                              <span class="link">{{ value.title }}</span>
                            </td>
                            <td class="text-center text-xss w-10">
                              <img
                                :src="value.icon"
                                class="border-radius-lg height-voucher"
                              />
                            </td>
                            <td class="text-center text-xss">
                              {{ value.number_of_vouchers }}
                            </td>
                            <td class="text-center text-xss">
                              {{ value.amount_convert }}
                            </td>
                            <td class="text-center text-xss">
                              {{ value.total }}
                            </td>
                            <td class="text-center text-xss">
                              {{ value.sold }}
                            </td>
                            <td class="text-center text-xss">
                              {{ value.expiry_date_format }}
                            </td>
                            <td class="align-middle text-center">
                              <span
                                class="badge badge-sm"
                                v-bind:class="
                                  value.check_status == 'Active'
                                    ? 'bg-gradient-success'
                                    : 'bg-danger'
                                "
                              >
                                {{ value.check_status }}</span
                              >
                            </td>
                            <td class="w-10 align-middle text-right text-sm">
                              <a
                                class="
                                  btn btn-link
                                  text-danger text-gradient
                                  px-2
                                  mb-0
                                "
                                data-bs-toggle="modal"
                                data-bs-target="#modalConfirm"
                                @click="
                                  confirmChangeStatus(value.id, value.status)
                                "
                              >
                                {{ value.status ? "De-activate" : "Active" }}
                              </a>
                              <a
                                class="btn btn-link text-dark px-2 mb-0"
                                @click="editVoucherBundle(value.id)"
                              >
                                <i
                                  class="fas fa-pencil-alt text-dark me-2"
                                  aria-hidden="true"
                                ></i
                                >Edit
                              </a>
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <td colspan="10" class="text-center">
                              No item available
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <pagination
                    v-if="pagination.last_page > 1"
                    :pagination="pagination"
                    :current_page="pagination.current_page"
                    :last_page="pagination.last_page"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalConfirm :message="messageConfirm" :process-yes="processYes" />
      <div class="position-fixed top-1 end-1 z-index-2 message-success">
        <material-snackbar
          v-if="snackbar === 'danger' || snackbar === 'success'"
          title=""
          date=""
          :description="message"
          :icon="{ component: 'campaign', color: 'white' }"
          :color="snackbar"
          :close-handler="closeSnackbar"
        />
      </div>
      <material-loading :active="loaderActive" />
    </div>
    <form :action="`${actionDownload}/merchant/download-voucher-bundle`" method="POST">
      <input type="hidden" name="merchantId" :value="merchantIdExport" />
      <input type="hidden" name="merchant_code" :value="merchantCodeExport" />
      <input type="hidden" name="token" :value="token" />
      <input type="hidden" name="search_keyword" :value="searchKeyword" />
      <input type="hidden" name="action_export" :value="action_export" />
      <input type="hidden" name="action" value="export" />
      <input id="downloadForm" type="submit" class="d-none" />
    </form>
  </div>
</template>

<script>
import MaterialSnackbar from "@/components/MaterialSnackbar.vue";
import "bootstrap/dist/js/bootstrap.bundle";
import MaterialLoading from "@/components/MaterialLoading.vue";
import Pagination from "@/components/Pagination.vue";
import "bootstrap/js/dist/modal";
import MaterialButton from "@/components/MaterialButton.vue";
import "@vuepic/vue-datepicker/dist/main.css";
import SettingVoucherService from "../../../services/settingVoucher.service";
import ModalConfirm from "@/components/ModalConfirm.vue";
import TokenService from "../../../services/token.service";
import $ from "jquery";
export default {
  name: "find-store",
  components: {
    MaterialSnackbar,
    MaterialLoading,
    MaterialButton,
    ModalConfirm,
    Pagination,
  },
  data() {
    return {
      message: "",
      snackbar: null,
      loaderActive: false,
      vouchers: [],
      searchKeyword: "",
      searchTimerId: null,
      pagination: {},
      page: 1,
      voucherId: null,
      isLoadData: false,
      merchantCode: "",
      messageConfirm: "Are you sure you want to de-activate ?",
      action_export: 'csv',
      actionDownload: process.env.VUE_APP_BASE_URL,
      token: TokenService.getLocalAccessToken(),
      merchantIdExport: localStorage.getItem("MERCHANT_ID", ""),
      merchantCodeExport: localStorage.getItem("MERCHANT_CODE", ""),
    };
  },
  created() {
    if (this.$route.query.page) {
      this.page = this.$route.query.page;
    }
    this.getList(this.page);
  },
  mounted() {
    const code = localStorage.getItem("MERCHANT_CODE", "");
    this.merchantCode = code ? `/${code}` : "";
  },
  methods: {
    exportFile(type = 'csv') {
      this.action_export = type;
      setTimeout(() => {
        $('#downloadForm').click();
      }, 150);
    },

    closeSnackbar() {
      this.snackbar = null;
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null;
      }, 3000);
    },

    onChangesearchKeyword(event) {
      this.searchKeyword = event.target.value;
      clearTimeout(this.searchTimerId);
      this.searchTimerId = setTimeout(() => {
        this.getList(this.page);
      }, 1000);
    },

    getList(page) {
      this.isLoadData = false;
      this.vouchers = [];
      this.showLoader();
      var dataForm = {
        search_keyword: this.searchKeyword,
        page: page,
      };
      SettingVoucherService.getDataVoucherBundle(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.vouchers = response.data.data.items;
            this.pagination = response.data.data;
            this.page = this.pagination.current_page;
            this.$router.push({
              query: Object.assign({}, this.$route.query, { page: this.page }),
            });
            this.isLoadData = true;
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    createVoucherBundle() {
      this.$router.push("/voucher-bundle");
    },

    editVoucherBundle(id) {
      this.$router.push(
        "/voucher-bundle/" + id + "?page=" + this.page
      );
    },

    processYes() {
      $(".btn-close-modal").click();
      this.showLoader();
      SettingVoucherService.changeStatusVoucherBundle(this.voucherId).then(
        (response) => {
          if (response.data.result.isSuccess) {
            if (this.vouchers.length == 1) {
              this.page = this.page - 1;
            }
            this.getList(this.page);
            this.snackbar = "success";
            if (response.data.data.status) {
              this.message = "Voucher is ativated";
            } else {
              this.message = "Voucher is deactivate";
            }
            this.setTimeOutNotification();
          } else {
            this.snackbar = "danger";
            this.message = response.data.result.message;
            this.setTimeOutNotification();
          }
          this.hideLoader();
        },
        (error) => {
          this.snackbar = "danger";
          this.message = error;
          this.hideLoader();
          this.setTimeOutNotification();
        }
      );
    },

    confirmChangeStatus(voucherId, status) {
      if (status) {
        this.messageConfirm = "Are you sure you want to de-activate?";
      } else {
        this.messageConfirm = "Are you sure you want to activate?";
      }
      this.voucherId = voucherId;
      $(".btn-confirm-modal").click();
    },
    showLoader() {
      this.loaderActive = true;
    },
    hideLoader() {
      this.loaderActive = false;
    },
  },
};
</script>
<style scoped>
.text-title {
  color: #82001e !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  font-family: "Roboto Slab", sans-serif !important;
}

.text-label {
  color: #5b848d !important;
  font-weight: 500 !important;
  font-size: 17px !important;
  font-family: "Roboto Slab", sans-serif !important;
}

.checkbox-auto-save label {
  padding-top: 11px !important;
}

input[type="search"] {
  border: none;
  background: transparent;
  margin: 0;
  padding: 7px 8px;
  font-size: 14px;
  color: inherit;
  border: 1px solid transparent;
  border-radius: inherit;
}

input[type="search"]::placeholder {
  color: #bbb;
  text-align: left;
  font-weight: normal;
}

input[type="search"]:focus {
  border-color: #72959d;
  outline: none;
  border: none;
  border-bottom: 2px solid #72959d;
}

.nosubmit-parent {
  border: none;
  padding: 0;
}

input.nosubmit {
  border-bottom: 1px solid #d2d2d2;
  width: 100%;
  padding: 9px 4px 9px 40px;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat 13px center;
}

.custom-title {
  word-break: break-all;
  max-width: 200px;
  white-space: normal;
}
.link {
  color: rgb(7, 7, 95);
}
.link:hover {
  cursor: pointer;
}
</style>