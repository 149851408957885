<template>
  <div>
    <div class="row mb-2 pe-4">
      <div class="col-lg-12 col-md-12 col-12 d-flex align-items-center">
        <div class="color-common">
          <h3 class="title-page mb-0 ps-3">DASHBOARD AND REPORTS</h3>
        </div>
      </div>
    </div>
    <div class="page-header align-items-start min-vh-100 my-transacions">
      <span class="mask bg-white"></span>
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12 mx-auto mt-4">
            <div class="row">
              <strong>
                ONLINE ORDERING REPORT
              </strong>
            </div>
            <div class="row mt-3">
              <div class="col-lg-4 col-md-12 col-12">
                <div class="row">
                  <div
                    class="col-lg-3 col-md-3 col-3 d-flex justify-content-end align-items-center"
                  >
                    Time
                  </div>
                  <div class="col-lg-8 col-md-6 col-9">
                    <Multiselect
                      v-model="time_type"
                      :options="times"
                      :canClear="false"
                      @change="timeChange($event)"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-12 col-12">
                <div class="row">
                  <div
                    class="col-lg-3 col-md-3 col-3 d-flex justify-content-end align-items-center"
                  >
                    Branch
                  </div>
                  <div class="col-lg-8 col-md-6 col-9">
                    <Multiselect
                      v-model="branch_id"
                      :options="branchs"
                      :canClear="false"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-12 col-12">
                <div class="row">
                  <div
                    class="col-lg-3 col-md-3 col-3 d-flex justify-content-end align-items-center"
                  >
                    Status
                  </div>
                  <div class="col-lg-8 col-md-6 col-9">
                    <Multiselect
                      v-model="status"
                      :options="statusArr"
                      :canClear="false"
                      @change="timeChange($event)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3" v-if="isShowTimeCustom">
              <div class="col-lg-4 col-md-12 col-12">
                <div class="row">
                  <div
                    class="col-lg-3 col-md-3 col-3 d-flex justify-content-end align-items-center"
                  >
                    From
                  </div>
                  <div class="col-lg-8 col-md-6 col-9">
                    <Datepicker
                      :enableTimePicker="false"
                      class="date-time-dashboad"
                      v-model="from_date"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-12 col-12 pt-custtom">
                <div class="row">
                  <div
                    class="col-lg-3 col-md-3 col-3 d-flex justify-content-end align-items-center"
                  >
                    To
                  </div>
                  <div class="col-lg-8 col-md-6 col-9">
                    <Datepicker
                      :enableTimePicker="false"
                      class="date-time-dashboad"
                      v-model="to_date"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-lg-12 col-md-12 col-12">
                <div class="row">
                  <div
                    class="col-lg-1 col-md-3 col-3 d-flex justify-content-end align-items-center"
                  >
                    Search
                  </div>
                  <div class="col-lg-8 col-md-6 col-9">
                    <input
                      class="nosubmit"
                      type="search"
                      id="keyword_search"
                      v-model="searchKeyword"
                      extraClass
                      name="keyword_search"
                      placeholder="Enter Branch Name/ID, Member Name, Email, ID..."
                      @keydown.enter="handleSearchEnter"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-4 ps-3">
              <div class="col-lg-9 col-md-9 col-3 d-flex">
                <div class="dropdown">
                  <button
                    class="btn btn-secondary dropdown-toggle btn-sm"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    aria-haspopup="true"
                    :disabled="disabled"
                  >
                    Download
                  </button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <a
                        class="dropdown-item"
                        @click="exportFile('csv')"
                        href="#"
                      >
                        Export CSV
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        @click="exportFile('xlsx')"
                        href="#"
                      >
                        Export Excel
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        @click="exportFile('pdf')"
                        href="#"
                        >Export PDF</a
                      >
                    </li>
                  </ul>
                </div>
                <div style="margin-left: 3vh;">
                  <material-button
                    class="btn-background btn-sm"
                    @click="filterData()"
                    style="width: 162.52px"
                  >
                    Run
                  </material-button>
                </div>
              </div>
            </div>
            <div class="row mt-1 ps-3">
              <div class="col-lg-9 col-md-12 col-12 d-flex justify-content-lg-start align-items-end">
                Count: {{ totalRecords }}
              </div>
              <div class="col-lg-3 col-md-3 col-3 d-flex justify-content-end">
                <ShowHideColumn
                  :columns="columns"
                  :changeCheckbox="changeCheckbox"
                  :columnCheckedCache="columnChecked"
                  :key="key"
                />
              </div>
            </div>
            <div class="row mt-4" style="min-height: 1120px;">
              <div class="col-12">
                <div class="card-custom">
                  <div class="px-0 pb-2">
                    <div class="table-responsive p-0 custom-table">
                      <table class="table align-items-center mb-0">
                        <thead>
                          <tr>
                            <th
                              class="text-center font-weight-bolder w-5"
                              v-if="columnShowed.includes('row_no')"
                            >
                              Row No
                            </th>
                            <template
                              v-for="(item, index) in headersData"
                              :key="index"
                            >
                              <th class="text-center font-weight-bolder" v-if="columnShowed.includes(item.field)">
                                <span
                                  :class="[
                                    !!item.hasSortBy
                                      ? 'wrapper-sortby pointer'
                                      : '',
                                    ESortDirectionSelect.idx == (index + 1)
                                      ? ESortDirectionSelect.direction === ESortDirection.Desc
                                        ? 'sort-desc'
                                        : 'sort-asc'
                                      : '',
                                  ]"
                                  @click="
                                    () => {
                                      !!item.hasSortBy &&
                                        handleChangeSortColumn(index + 1);
                                    }
                                  "
                                >{{ item.label }}</span>
                              </th>
                            </template>
                          </tr>
                        </thead>
                        <tbody v-if="posTransactionData.length">
                          <tr
                            v-for="(value, key) in posTransactionData"
                            :key="key"
                          >
                            <td
                              class="text-center text-xss w-5"
                              v-if="columnShowed.includes('row_no')"
                            >
                              {{ key + 1 }}
                            </td>
                            <td
                              class="text-center text-xss w-5"
                              v-if="columnShowed.includes('uuid')"
                            >
                              {{ value.uuid }}
                            </td>
                            <td
                              class="text-center text-xss w-5"
                              v-if="columnShowed.includes('receipt_no')"
                            >
                              {{ value.receipt_no }}
                            </td>
                            <td
                              class="text-left text-xss w-25"
                              v-if="columnShowed.includes('transaction_id')"
                            >
                              {{ value.transaction_id }}
                            </td>
                            <td
                              class="text-left text-xss w-25"
                              v-if="columnShowed.includes('stripe_id')"
                            >
                              {{ value.stripe_id }}
                            </td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('created_at_format')"
                            >
                              {{ value.created_at_format }}
                            </td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('updated_at_format')"
                            >
                              {{ value.updated_at_format }}
                            </td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('branch_name')"
                            >
                              {{ value.branch_name }}
                            </td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('member_id_format')"
                            >
                              {{ value.member_id_format }}
                            </td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('member_name')"
                            >
                              {{ value.member_name }}
                            </td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('member_email')"
                            >
                              {{ value.member_email }}
                            </td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('tier_name')"
                            >
                              {{ value.tier_name }}
                            </td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('total_amount_format')"
                            >
                              {{ value.total_amount_format }}
                            </td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('sub_total_format')"
                            >
                              {{ value.sub_total_format }}
                            </td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('discount_code')"
                            >
                              {{ value.discount_code }}
                            </td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('voucher_code')"
                            >
                              {{ value.voucher_code }}
                            </td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('amount_direct_deduct_format')"
                            >
                              {{ value.amount_direct_deduct_format }}
                            </td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('discount_label')"
                            >
                              {{ value.discount_label }}
                            </td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('discount_value_format')"
                            >
                              {{ value.discount_value_format }}
                            </td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('service_charge_amount_format')"
                            >
                              {{ value.service_charge_amount_format }}
                            </td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('gst_amount_format')"
                            >
                              {{ value.gst_amount_format }}
                            </td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('cess_amount_format')"
                            >
                              {{ value.cess_amount_format }}
                            </td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('take_away_charge_amount_format')"
                            >
                              {{ value.take_away_charge_amount_format }}
                            </td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('status')"
                            >
                              {{ value.status }}
                            </td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('dining_option')"
                            >
                              {{ value.dining_option }}
                            </td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('is_cutlery')"
                            >
                              {{ value.is_cutlery }}
                            </td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('customer_remark')"
                            >
                              {{ value.customer_remark }}
                            </td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('guest')"
                            >
                              {{ value.guest }}
                            </td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('tag')"
                            >
                              {{ value.tag }}
                            </td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('note')"
                            >
                              {{ value.note }}
                            </td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('table_remarks')"
                            >
                              {{ value.table_remarks }}
                            </td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('foc_remarks')"
                            >
                              {{ value.foc_remarks }}
                            </td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('operation_id')"
                            >
                              {{ value.operation_id }}
                            </td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('rounding')"
                            >
                              {{ value.rounding }}
                            </td>
                            <td
                              class="text-center text-xss w-10"
                              v-if="columnShowed.includes('payment_method')"
                            >
                              {{ value.payment_method }}
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <td colspan="29" class="text-center">
                              No item available
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <pagination
                    v-if="pagination.last_page > 1"
                    :pagination="pagination"
                    :current_page="pagination.current_page"
                    :last_page="pagination.last_page"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="position-fixed top-1 end-1 z-index-2 message-success">
        <material-snackbar
          v-if="snackbar === 'danger' || snackbar === 'success'"
          title=""
          date=""
          :description="message"
          :icon="{ component: 'campaign', color: 'white' }"
          :color="snackbar"
          :close-handler="closeSnackbar"
        />
      </div>
      <material-loading :active="loaderActive" />
    </div>
    <form
      :action="`${actionDownload}/merchant/download-online-ordering-report`"
      method="POST"
    >
      <input type="hidden" name="merchantId" :value="merchantIdExport" />
      <input type="hidden" name="merchant_code" :value="merchantCode" />
      <input type="hidden" name="token" :value="token" />
      <input type="hidden" name="list_field_show" :value="columnShowed" />
      <input type="hidden" name="search_keyword" :value="searchKeyword" />
      <input type="hidden" name="time_type" :value="time_type" />
      <input type="hidden" name="from_date" :value="dateFormat(from_date)" />
      <input type="hidden" name="to_date" :value="dateFormat(to_date)" />
      <input type="hidden" name="branch_id" :value="branch_id" />
      <input type="hidden" name="status" :value="status" />
      <input type="hidden" name="checkExport" :value="checkExport" />
      <input type="hidden" name="action_export" :value="action_export" />
      <input type="hidden" name="action" value="export" />
      <input id="downloadForm" type="submit" class="d-none" />
    </form>
  </div>
</template>

<script>
import MaterialSnackbar from '@/components/MaterialSnackbar.vue'
import 'bootstrap/dist/js/bootstrap.bundle'
import MaterialLoading from '@/components/MaterialLoading.vue'
import Pagination from '@/components/Pagination.vue'
import 'bootstrap/js/dist/modal'
import '@vuepic/vue-datepicker/dist/main.css'
import Multiselect from '@vueform/multiselect'
import '@vueform/multiselect/themes/default.css'
import MaterialButton from '@/components/MaterialButton.vue'
import ShowHideColumn from '@/components/ShowHideColumn.vue'
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import moment from 'moment'
import DashboardReportService from '../../services/dashboardReport.service'
import $ from 'jquery'
import TokenService from '../../services/token.service'

export default {
  name: 'campaign-list',
  components: {
    MaterialSnackbar,
    MaterialLoading,
    MaterialButton,
    Pagination,
    Multiselect,
    Datepicker,
    ShowHideColumn
  },
  data() {
    return {
      token: TokenService.getLocalAccessToken(),
      actionDownload: process.env.VUE_APP_BASE_URL,
      merchantIdExport: localStorage.getItem("MERCHANT_ID", ""),
      merchantCode: localStorage.getItem('MERCHANT_CODE', ''),
      message: '',
      snackbar: null,
      loaderActive: false,
      searchKeyword: '',
      searchTimerId: null,
      pagination: {},
      page: 1,
      columns: [
        { value: 'all', label: 'All' },
        { value: 'row_no', label: 'Row No' },
        { value: 'uuid', label: 'UUID' },
        { value: 'receipt_no', label: 'Receipt No' },
        { value: 'transaction_id', label: 'Transaction ID' },
        { value: 'stripe_id', label: 'Stripe ID' },
        { value: 'created_at_format', label: 'Order Date Time' },
        { value: 'updated_at_format', label: 'Last Updated' },
        { value: 'branch_name', label: 'Branch' },
        { value: 'member_id_format', label: 'Member ID' },
        { value: 'member_name', label: 'Member Name' },
        { value: 'member_email', label: 'Member Email' },
        { value: 'tier_name', label: 'Tier' },
        { value: 'total_amount_format', label: 'Total Amount' },
        { value: 'sub_total_format', label: 'Sub Total' },
        { value: 'discount_code', label: 'Discount Code' },
        { value: 'voucher_code', label: 'Voucher Code' },
        { value: 'amount_direct_deduct_format', label: 'Deducted Amount' },
        { value: 'discount_label', label: 'Discount Label' },
        { value: 'discount_value_format', label: 'Discount Value' },
        { value: 'service_charge_amount_format', label: 'Service Charge' },
        { value: 'gst_amount_format', label: 'GST' },
        { value: 'cess_amount_format', label: 'Cess' },
        { value: 'take_away_charge_amount_format', label: 'Take Away Charge' },
        { value: 'status', label: 'Status' },
        { value: 'dining_option', label: 'Dining Option' },
        { value: 'is_cutlery', label: 'Cutlery' },
        { value: 'customer_remark', label: 'Customer Remark' },
        { value: 'guest', label: 'Guest' },
        { value: 'tag', label: 'Tag' },
        { value: 'note', label: 'Note' },
        { value: 'table_remarks', label: 'Table Remarks' },
        { value: 'foc_remarks', label: 'Foc Remarks' },
        { value: 'operation_id', label: 'Operation ID' },
        { value: 'rounding', label: 'Rounding' },
        { value: 'payment_method', label: 'Payment Method' },
      ],
      reward_type: 0,
      time_type: 0,
      times: [
        { value: 0, label: 'Select Time' },
        { value: 1, label: 'Today' },
        { value: 2, label: 'Yesterday' },
        { value: 3, label: 'Last 7 days' },
        { value: 4, label: 'Last 30 days' },
        { value: 5, label: 'This month' },
        { value: 6, label: 'Last 3 months' },
        { value: 7, label: 'This year' },
        { value: 8, label: 'Last year' },
        { value: 9, label: 'Custom' },
      ],
      from_date: null,
      to_date: null,
      isShowTimeCustom: false,
      action: 'list',
      action_export: 'csv',
      totalRecords: 0,
      columnShowed: [],
      branchs: [],
      branch_id: 0,
      posTransactionData: [],
      columnChecked: [],
      checkExport: false,
      statusArr: [],
      status: '',
      ESortDirectionSelect: {
        idx: null,
        direction: null
      },
      ESortDirection: {
        Asc: "asc",
        Desc: "desc",
      },
    }
  },
  created() {
    this.getDataFilter()
    if (this.$route.query.page) {
      this.page = this.$route.query.page
    }
    if(window.localStorage.getItem("OnlineOrderingColumn")){
      this.columnChecked = window.localStorage.getItem("OnlineOrderingColumn").split(",");
      this.key = Math.random();
    }
  },
  computed: {
    headersData() {
      return [
        {
          label: "UUID",
          field: "uuid",
          hasSortBy: true,
        },
        {
          label: "Receipt No",
          field: "receipt_no",
          hasSortBy: true,
        },
        {
          label: "Transaction ID",
          field: "transaction_id",
          hasSortBy: true,
        },
        {
          label: "Stripe ID",
          field: "stripe_id",
          hasSortBy: true,
        },
        {
          label: "Order Date Time",
          field: "created_at_format",
          hasSortBy: true,
        },
        {
          label: "Last Updated",
          field: "updated_at_format",
          hasSortBy: true,
        },
        {
          label: "Branch",
          field: "branch_name",
          hasSortBy: true,
        },
        {
          label: "Member ID",
          field: "member_id_format",
          hasSortBy: true,
        },
        {
          label: "Member Name",
          field: "member_name",
          hasSortBy: true,
        },
        {
          label: "Member Email",
          field: "member_email",
          hasSortBy: true,
        },
        {
          label: "Tier",
          field: "tier_name",
        },
        {
          label: "Total Amount",
          field: "total_amount_format",
          hasSortBy: true,
        },
        {
          label: "Sub Total",
          field: "sub_total_format",
          hasSortBy: true,
        },
        {
          label: "Discount Code",
          field: "discount_code",
          hasSortBy: true,
        },
        {
          label: "Voucher Code",
          field: "voucher_code",
          hasSortBy: true,
        },
        {
          label: "Deducted Amount",
          field: "amount_direct_deduct_format",
          hasSortBy: true,
        },
        {
          label: "Discount Label",
          field: "discount_label",
        },
        {
          label: "Discount Value",
          field: "discount_value_format",
          hasSortBy: true,
        },
        {
          label: "Service Charge",
          field: "service_charge_amount_format",
          hasSortBy: true,
        },
        {
          label: "GST",
          field: "gst_amount_format",
          hasSortBy: true,
        },
        {
          label: "Cess",
          field: "cess_amount_format",
          hasSortBy: true,
        },
        {
          label: "Take Away Charge",
          field: "take_away_charge_amount_format",
          hasSortBy: true,
        },
        {
          label: "Status",
          field: "status",
          hasSortBy: true,
        },
        {
          label: "Dining Option",
          field: "dining_option",
        },
        {
          label: "Cutlery",
          field: "is_cutlery",
        },
        {
          label: "Customer Remark",
          field: "customer_remark",
        },
        {
          label: "Guest",
          field: "guest",
        },
        {
          label: "Tag",
          field: "tag",
        },
        {
          label: "Note",
          field: "note",
        },
        {
          label: "Table Remarks",
          field: "table_remarks",
        },
        {
          label: "Foc Remarks",
          field: "foc_remarks",
        },
        {
          label: "Operation ID",
          field: "operation_id",
        },
        {
          label: "Rounding",
          field: "rounding",
        },
        {
          label: "Payment Method",
          field: "payment_method",
        },
      ];
    },
  },
  methods: {
    exportFile(type = 'csv') {
      if (this.columnShowed.length == 0) {
        this.snackbar = 'danger'
        this.message = 'You must select least 1 column to export file.'
        this.setTimeOutNotification()
      } else {
        this.action_export = type
        setTimeout(() => {
          $('#downloadForm').click()
        }, 150)
      }
    },
    changeCheckbox(columnShowed) {
      this.columnShowed = columnShowed
      window.localStorage.setItem("OnlineOrderingColumn", columnShowed)
    },
    timeChange($event) {
      if ($event == 9) {
        this.isShowTimeCustom = true
      } else {
        this.isShowTimeCustom = false
      }
    },

    filterData() {
      this.getList(this.page)
    },

    dateFormat(date) {
      if (date == null) {
        return null
      } else {
        return moment(date).format('YYYY-MM-DD')
      }
    },

    closeSnackbar() {
      this.snackbar = null
    },

    setTimeOutNotification() {
      setTimeout(() => {
        this.snackbar = null
      }, 3000)
    },

    handleChangeSortColumn(idx) {
      if (this.ESortDirectionSelect.idx && this.ESortDirectionSelect.idx == idx) {
        this.ESortDirectionSelect.direction = this.ESortDirectionSelect.direction === this.ESortDirection.Asc ? this.ESortDirection.Desc : this.ESortDirection.Asc;
      } else {
        this.ESortDirectionSelect = {
          idx: idx,
          direction: this.ESortDirection.Asc
        }
      }
      
      if (this.posTransactionData.length) {
        this.getList(this.page);
      }
    },

    getList(page) {
      this.totalRecords = 0
      this.showLoader()
      var dataForm = {
        action: this.action,
        branch_id: this.branch_id,
        search_keyword: this.searchKeyword,
        time_type: this.time_type,
        from_date: this.dateFormat(this.from_date),
        to_date: this.dateFormat(this.to_date),
        type: this.type,
        action_export: this.action_export,
        checkExport: this.checkExport,
        status: this.status,
        page: page,
        sortCol: this.ESortDirectionSelect.idx,
        sortType: this.ESortDirectionSelect.direction,
      }

      DashboardReportService.getDataOnlineOrderingReport(dataForm).then(
        (response) => {
          if (response.data.result.isSuccess) {
            this.posTransactionData = response.data.data.items
            this.pagination = response.data.data
            this.page = this.pagination.current_page
            this.totalRecords = this.pagination.total
            if (this.pagination.current_page > this.pagination.last_page) {
              this.page = 1
              this.getList(this.page)
            }
            this.$router.push({
              query: Object.assign({}, this.$route.query, { page: this.page }),
            })
            this.checkExport = true;
          } else {
            this.snackbar = 'danger'
            this.message = response.data.result.message
            this.setTimeOutNotification()
          }
          this.hideLoader()
        },
        (error) => {
          this.snackbar = 'danger'
          this.message = error
          this.hideLoader()
          this.setTimeOutNotification()
        },
      )
    },

    showLoader() {
      this.loaderActive = true
    },

    hideLoader() {
      this.loaderActive = false
    },

    getDataFilter() {
      this.showLoader()
      DashboardReportService.getFilterDataTopupReport().then(
        (response) => {
          if (response.data.result.isSuccess) {
            var DataFilter = response.data.data
            this.branchs = DataFilter.branches;
            this.statusArr = response.data.data.status;
          } else {
            this.snackbar = 'danger'
            this.message = response.data.result.message
            this.setTimeOutNotification()
          }
          this.hideLoader()
        },
        (error) => {
          this.snackbar = 'danger'
          this.message = error
          this.hideLoader()
          this.setTimeOutNotification()
        },
      )
    },
    handleSearchEnter(){
      this.getList(this.page);
    },
  },
}
</script>
<style scoped>
table thead th:not(:first-child) {
  min-width: 150px;
}

table thead th > span {
  position: relative;
}

input[type='search'] {
  border: none;
  background: transparent;
  margin: 0;
  padding: 7px 8px;
  font-size: 14px;
  color: inherit;
  border: 1px solid transparent;
  border-radius: inherit;
}

input[type='search']::placeholder {
  color: #bbb;
  text-align: left;
  font-weight: normal;
}

input[type='search']:focus {
  border-color: #72959d;
  outline: none;
  border: none;
  border-bottom: 2px solid #72959d;
}

.nosubmit-parent {
  border: none;
  padding: 0;
}

input.nosubmit {
  border-bottom: 1px solid #d2d2d2;
  width: 100%;
  padding: 9px 4px 9px 40px;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat 13px center;
}

.date-time-dashboad .dp__input_wrap {
  min-width: 200px !important;
  width: 250px;
}

.custom-control-label label {
  padding-top: 14px !important;
}

.pt-custtom {
    padding-top: 7px;
}
</style>
